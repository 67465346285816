.homescreen__loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #000000; /* Blue */
    border-bottom: 16px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 110px;
    height: 110px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 30%;
    left: 45%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .homescreen__loadtext {
    font-size: 150%;
    position: absolute;
    top: 20%;
    left: 46%;
  }
  @media (max-width: 960px) { 
    .homescreen {
      width: 100%;
      height: 100%;
    }
    .homescreen__loading {
      border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    border-bottom: 5px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 30%;
    left: 47%;
    z-index: 500;
    margin-top: 30px;
    }
  
    .homescreen__loadtext {
      display: none;
    }
  }