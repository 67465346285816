.eventsettingdrawer { 
    visibility: hidden;
    opacity: 0;
} 

.eventsettingdrawer.show {
    visibility: visible;
    opacity: 1;
}

.eventsetting__main {
    color: white;
    margin-top: 2px;
    background:black;
    display: flex;
    flex-direction: column;
    font-size: 90%;
    padding: 4px 6px;
    width: 150px;
    position: absolute;
    right:-140px;
    top:inherit;
    z-index: 10;
}
.eventsetting__who {
    text-align: center;
    font-size: 100%;
    color: rgb(206, 206, 206);
}

.eventsetting__invite {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.eventsetting__invite__add .icon {
    padding: 1px 1px;
    background: gold;
    color: black;
    border-radius: 50%;
    font-size: 120%;
    font-weight: 700;
    margin-bottom: 2px;
}
.eventsetting__invite__add .icon:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}

.eventsetting__public {
    margin: 3px 8px;
    text-align: center;
}

.eventsetting__public__select {
    background: gold;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__public:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}
.eventsetting__friend {
    margin: 3px 5px;
    text-align: center;
}
.eventsetting__friend__select {
    background: gold;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__friend:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}
.eventsetting__me {
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__me__select {
    background: gold;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__me:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}

.eventsetting__edit {
    background: rgb(30, 92, 208);
    color: white;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__edit:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}
.eventsetting__delete {
    background: rgb(201, 20, 0);
    color: white;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__map {
    background: rgb(46, 46, 46);
    color: gold;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__delete:hover, .eventsetting__map:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}

@media (max-width: 960px) { 
    .eventsetting__main {
        color: white;
        margin-top: 2px;
        background:black;
        display: flex;
        flex-direction: column;
        font-size: 90%;
        padding: 4px 6px;
        width: 150px;
        position: absolute;
        right:70px;
        top:inherit;
        z-index: 50;
    }
}