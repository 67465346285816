.notification { 
    width: 300px; 
    height: 90%;
    background: rgb(36, 36, 36);
    position: fixed;
    top: 50px;;
    right:5px;
    z-index: 200;
    transform: translateX(+105%); 
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    color: white;
    /*justify-content: center;*/
} 

.notification.show {
    transform: translateX(0);
}

.notification__title {
    font-size: 150%;
    
    margin: 5px 10px;
}

