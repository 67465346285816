.accountsearch__detail {
    display: flex;
    margin: 5px 5px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(63, 63, 63);
    box-shadow: 3px 3px rgba(0,0,0,0.4);
    cursor: pointer; 
}

.accountsearch__detail:hover {
    transform: scale(1.03);
    transition: .25s ease-out;
    background: rgb(0, 0, 0);
    color: white;
}

.accountsearch__left {
    flex: 0.18;
    
}
.accountsearch__pic {
    margin: 5px 5px;
    display: block;
    height: 45px;
    width: 45px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%;
    border: 1px solid rgb(63, 63, 63);
}



.accountsearch__info {
    flex: 0.82;
    margin-top: 3px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}





