@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}



.eventscreate { 
    
    background: rgb(255, 255, 255);
    position:absolute;
    top:21%;
    left:29%;
    z-index: 200;
    visibility: hidden;
    opacity: 0;
    /*transform: translateY(-500%); */
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    border-radius: 10px;
} 

.eventscreate.show {
    visibility: visible;
    opacity: 1;
    /*transform: translateY(0);*/
}

.create__title {
    height: 40px;
    font-size: 18px;
    padding: 5px 5px;
    background: black;
    color: white;
    text-align: center;
}

.create__date {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 5px 5px;
}
.create__date .icon{
    margin-top: 5px;
}

.create__date .icon:hover{
    transform: scale(1.25);
    transition: .25s ease-out;
    color: #0e0f10;
    cursor: pointer;
}

.create__optionicon {
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(9,1fr);
    width: 100%;
}

.create__icon {
    margin-top: 5px;
    background: rgb(230, 230, 230);
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 20px;
    cursor: pointer;  
    font-size: 160%;
    text-align: center;
}

.create__selectedicon {
    margin-top: 5px;
    background: rgb(230, 230, 230);
    box-shadow:0 0 2px 2px rgb(72, 72, 72);
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 20px;
    cursor: pointer;  
    font-size: 160%;
    text-align: center;
}

.create__optioncolor {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(10,1fr);
    width: 100%;
}

.create__color {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;  
}

.create__selectcolor {
    /*box-shadow: 4px 4px rgba(0,0,0,0.4);*/
    /*border: 2px solid rgb(63, 63, 63);*/
    box-shadow:0 0 3px 3px rgb(72, 72, 72);
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;  
}
.create__alllocation {
    margin: 5px 5px;
    display: flex;
    flex-direction: row;
    
}
.create__leftlocation {
    flex: 0.9;
    
}
.create__rightlocation {
    margin-left: 5px;
    margin-top: 2px;
    flex: 0.1;
    
}
.create__checklocation {
    height: 50px;
    font-size: 80%;
}




.create__detail {
    margin: 10px 5px;
}

.create__time {
    display:flex;
    width: 100%;
    justify-content: space-between;
    
}

.create__starthour {
    margin-left: 5px;
    flex: 0.25;
}

.create__startminute {
    flex: 0.25;
    margin-right: 30px;
}
.create__endhour {
    margin-left: 5px;
    flex: 0.25;
}

.create__endminute {
    flex: 0.25;
    margin-right: 30px;
}

.create__timetitle {
    display:flex;
    width: 100%;
    justify-content: space-between;
    
    margin-bottom: 5px;
}

.title__starttime {
    margin-left: 10px;
    
}
.title__endtime {
    margin-left: 170px;
   
}
.title__duration__OK {
    margin-right: 10px;
    color: green;
}
.title__duration__NG {
    margin-right: 10px;
    color: red;
}
.create__createbtn{
    margin: 10px 10px;
    text-align: center;
    justify-items: center;
}
.aaaaa {
    width: 100%;
}

.create__invite {
    position: absolute;
    top: -2px;
    right: 10px;
    font-size: 160%;
    color: rgb(255, 255, 255);
    text-align: center;
}
.create__invite__amount {
    position: absolute;
    top: 12px;
    right: 40px;
    font-size: 70%;
    background: gold;
    color: black;
    text-align: center;
    padding: 1px 5px;
    border-radius: 10px;
    font-weight: 700;
}

.create__invite > span {
    cursor: pointer;
}

.create__main {
    width: 500px;
    height: 550px;
}

.create__maininvite {
    width: 800px;
    display: flex;
    height: 550px;
}

.create__leftshow {
    flex: 0.62;
    height: 550px;
    border-radius: 10px;
}

.create__lefthide {
    width: 500px;
    height: 550px;
}

.create__centershow{
    flex: 0.07;
    display: flex;
    flex-direction: column;
    background:rgb(148, 148, 148);
    height: 550px;
    transition: 0.25s all;
}

.create__centerhide{
    width: 0px;
    height: 550px;
}

.create__with {
    margin-top: 10px;
    margin-left: 8px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: rgb(223, 223, 223);  
    justify-content: center;
    text-align: center;
    font-size: 160%;
    color: white;
    font-weight: 700;
}

.create__rightshow{
    flex: 0.31;
    display: flex;
    flex-direction: column;
    background:rgb(228, 228, 228);
    height: 550px;
    transition: 0.25s all;
    border-radius: 10px;
}

.create__righthide{
    width: 0px;
    height: 550px;
}

.create__friendlist {
    overflow-y: scroll;
    max-height: 500px;
}
.create__friendlist__not {
    margin-top: 10px;
    font-size: 100%;
    text-align: center;
    font-family: 'Lato', sans-serif;
    color: rgb(0, 0, 0);
    height: fit-content;
}

.friend__search {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 80%;
    background-color: white;
    border-radius: 5px;
    padding: 0.5px 15px;   
    
}

@media (max-width: 960px) { 
    .eventscreate { 
        width: 100%; 
        height: 520px;
        background: transparent;
        position:fixed;
        top:0;
        left:0;
        z-index: 200;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
    }  
    .eventscreate.show {
        visibility: visible;
        opacity: 1;
    }
    .create__title {
        height: 40px;
        font-size: 18px;
        padding: 5px 5px;
        background: black;
        color: white;
        text-align: center;
    }
    .create__main {
        width: 100%;
        height: 100%;
    }
    
    .create__lefthide {
        width: 100%;
        height: 100%;
        background: white;
    }
    .create__optioncolor {
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(10,1fr);
        width: 100%;
    }
    
    .create__color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;  
    }
    .create__selectcolor {
        box-shadow:0 0 3px 3px rgb(72, 72, 72);
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;  
    }
    .create__optionicon {
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(9,1fr);
        width: 100%;
    }
    
    .create__icon {
        margin-top: 5px;
        background: rgb(230, 230, 230);
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;  
        font-size: 130%;
        text-align: center;
    }
    
    .create__selectedicon {
        margin-top: 5px;
        background: rgb(230, 230, 230);
        box-shadow:0 0 2px 2px rgb(72, 72, 72);
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;  
        font-size: 130%;
        text-align: center;
    }
    .create__timetitle {
        display:flex;
        width: 100%;
        justify-content: space-between;
        
        margin-bottom: 5px;
    }
    .title__duration__OK {
        margin-top: 5px;
        margin-right: 20px;
        color: green;
        font-size: 60%;
    }
    .title__duration__NG {
        margin-top: 5px;
        margin-right: 20px;
        color: red;
        font-size: 60%;
    }
    .title__endtime {
        margin-left: 120px;
    }
    .create__maininvite {
        width: 100%;
        display: flex;
        
        height: 100%;
    }
    .create__maininvite > .create__leftshow {
        flex: 0;
        display: none;
    }
    .create__maininvite > .create__centershow{
        flex: 0.15;
        display: flex;
        flex-direction: column;
        background:rgb(148, 148, 148);
        height: 100%;
        transition: 0.25s all;
        overflow-y: scroll;
    
    }
    .create__maininvite > .create__rightshow{
        flex: 0.85;
        display: flex;
        flex-direction: column;
        background:rgb(228, 228, 228);
        height: 100%;
        transition: 0.25s all;
        border-radius: 10px;    
    }
}