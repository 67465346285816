.logindrawer { 
    width: 260px; 
    height: fit-content;
    background: rgb(247, 247, 247);
    position: fixed;
    top:50px;
    right:0;
    z-index: 200;
    transform: translateX(+100%); 
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    border-radius: 10px;
} 

.logindrawer.show {
    transform: translateX(0);
}


.logindrawer__links {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.logindrawer__links > li {
    display: flex;
    align-items: center;  
}

.logindrawer__links > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    padding: 1rem;
    text-decoration: none;
    color: #171717;
    font-size: 1.0rem;
}

.logindrawer__links > li > a:hover {
    background: #171717;
    color: #f4f4f4
}

.logindrawer__title {
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    font-size: 120%;
}

.user__main {
    display: flex;
    flex-direction: column;
}

.user__area1 {
    flex: 0.4;
}

.user__pic {
    display: block;
    margin: 10px 75px;
    object-fit:cover;
    width: 100px;
    height: 100px;
    overflow:hidden;
    border-radius: 50px;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
  }



.user__name {
    text-align: center;
    font-size: 130%;
}
.user__email {
    text-align: center;
}
.user__link {
    text-align: center;
}
.user__pkey {
    margin-top: 5px;
    margin-bottom: 5px;
    background: black;
    color: gold;
    text-align: center;
}
.user__pkey__buy {
    margin-left: 10px;
    
    background:grey;
    text-align: center;
    border-radius: 10px;
    color:white;
    padding: 0px 5px;
    font-size: 85%;
    cursor:not-allowed;
}
.user__btn {
    /*justify-items: center;*/
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    
}
.user__btn2 {
    /*justify-items: center;*/
    text-align: center;
    margin-top: 5px;
}
.feedback__send__btn{
    padding: 5px 10px;
    background:rgb(0, 97, 194);
    color: white;
    border-radius: 5px;
    letter-spacing: 1px;  
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    cursor: pointer;
    
}

.user__btn:hover, .user__setting:hover,.feedback__send__btn:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

.user__setting {
    position: absolute;
    right:5px;
    top:-10px;
    background:transparent;
    text-align: center;
    color:rgb(106, 106, 106);
    padding: 5px 5px;
    font-size:30px;
    z-index:10;
    cursor: pointer;
}

.user__feedback__text {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}