.userscreen {
    display:flex;

    max-width:1450px;
    /*align-items: center;*/
    justify-content: center;
    margin: 1rem auto;
}



.userscreen__primary {
    flex: 0.75;
    background: rgb(19, 18, 18);
    box-shadow: 0 4px 4px rgba(0,0,0,0.4);
    height: fit-content;
    border-radius: 5px;
    margin-left: 1rem;
    margin-right: 1rem;
    
    /*padding-bottom: 15px*/;
}

.userscreen__secondary {
    flex: 0.25;
    background: rgb(232, 232, 232);
    box-shadow: 0 1px 4px rgba(0,0,0,0.4);
    border-radius: 5px;
    margin-right: 1rem;
    flex-direction: column;
    height: 669px;
}

.userscreen__primary2 {
  flex: 0.75;
  background: rgb(19, 18, 18);
  box-shadow: 0 4px 4px rgba(0,0,0,0.4);
  height: fit-content;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  
  /*padding-bottom: 15px*/;
}

.userscreen__secondary2 {
  flex: 0.25;
  background: rgb(232, 232, 232);
  box-shadow: 0 1px 4px rgba(0,0,0,0.4);
  border-radius: 5px;
  margin-right: 1rem;
  flex-direction: column;
  height: 669px;
}

.userscreen__secondary__hide {
  flex:0;
  opacity: 0;
}

/* ****************** SCHEDULE COMPONENT STYLE  ************************ */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon, .icon2, .icon__friend,.icon__favourite,.icon2__favourite {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* GENERAL */

.schedule__header { /*reactcalendar เดี๋ยวจะเอามาทำหน้าแสดง Profile*/
    display: block;
    width: 100%;
    padding: 0.5em 0;
    /*border-bottom: 1px solid #eee;*/
    background: rgb(0, 0, 0);
}
.schedule__header #logo {
    font-size: 175%;
    text-align: center;
    color: #ffffff;
    line-height: 1;
}

.schedule__header #logo .icon {
    padding-right: .25em;
}

.schedule__body { 
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: #777;
    background: #000000;
    position: relative;
    
    box-shadow: -10px 10px 10px 0px #000000;

}

.schedule__body > main { /*Zone  ปฏิทิน*/
    
    display: block;
    margin: 0 auto;
  }

/* Zone Profile ด้านบนปฏิทิน*/


.header__account {
    width: 100%;
    height: 75px; 
    background-color: #000000 ;
    display: flex; 
    /*justify-content: space-between;*/
}

.account__left {
    margin-left: 10px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
  }

.account__pic {
    display: block;
    margin-top: 3px;
    object-fit:cover;
    width: 70px;
    height: 70px;
    overflow:hidden;
    cursor: pointer;  
    border-radius:50%;
    box-shadow: 4px 2px 7px gold;
  }

  .account__center {
    flex: 0.8;
    display: flex; 
    justify-content: space-between;
    margin-right: 20px;
  }
  .account__nameplate {
    display: flex;
    flex: 0.45;
    flex-direction: column;
    text-align: left;
  }
  .account__stat {
    flex: 0.55;
    justify-content: space-between;
    display: flex; 
  }

  .account__name {
    font-size: 30px;
    color: #fff;
  }
  .account__id {
    font-size: 18px;
    color: rgb(228, 232, 8);
  }

  .account__id__badge {
    margin-left: 2px;
    margin-bottom: 5px;
    background:rgb(0, 95, 184);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}
.account__id__badge:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}



  .account__follower {
    margin-left: 50px;
  }

  .account__follower > h2 {
    font-size: 30px;
    color: #fff;
    text-align: right;
    cursor: pointer;
    
  }
  .account__follower > p {
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  .account__following {
    margin-left: 50px;
  }

  .account__following > h2 {
    font-size: 30px;
    color: #fff;
    text-align: right;
    cursor: pointer;
    
  }
  .account__following > p {
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  .account__event {
    margin-left: 50px;
  }

  .account__event > h2 {
    font-size: 30px;
    color: #fff;
    text-align: right;
    
  }
  .account__event > p {
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  .account__right {
    flex: 0.2;
    display: flex;
    flex-direction: column;
  }

  .account__righttop{
    flex: 0.5;
  }

  .account__public {
    width: fit-content;
    position:absolute;
    right: 1px;
    height: fit-content;
    background: rgb(30, 92, 208);
    color: #ffffff;
    display: flex;
    margin-right: 10px;
    padding: 0 0.5rem;
    border-radius: 7px;
    margin-bottom: 10px;
    font-size: 110%;
    font-weight: 500;
  }

  .account__public > span{
    margin-left: 5px;
    margin-top: 8px;
    font-size: 70%;
    
  }

  .account__followbtn {
    cursor: pointer;
  }

  .account__public > span:hover{
    transform: scale(1.25);
    transition: .25s ease-out;
    cursor: pointer;  
  }
  

  

  .account__rightbottom{
    flex: 0.5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .account__relation {
    margin-top: 11px;
    margin-right: 11px;
    background: rgb(0, 43, 9);
    border-radius: 10px;
    padding: 3px 5px;
  }
  .account__relation > span {
   
    margin-left: 5px;
    font-size: 200%;
    color: white;
    /*cursor: pointer;*/
  }

  .account__toggle {
    color:gold;
    font-size: 80%;
  }


.account__relation > .icon {
    cursor:pointer;
    color: #ffffff;
}
.account__relation > .icon2 {
  cursor: pointer;
  color: gold;
}
.account__relation > .icon__friend {
  cursor:default;
  color: gold;
}
.account__relation > .icon__favourite {
  cursor:pointer;
  color: #ffffff;
}
.account__relation > .icon2__favourite {
cursor: pointer;
color: gold;
}
.account__relation > .icon:hover,  .account__relation > .icon2:hover,.account__relation > .icon__favourite:hover,.account__relation > .icon2__favourite:hover {
  transform: scale(1.20);
  transition: .25s ease-out;
}

.eventactive__title {
  font-size: 120%;
  text-align: center;
  background: rgb(0, 0, 0);
  color: #fff;
  height: fit-content;
  justify-content: space-between;
  display: flex;
}
.eventactive__today {
  margin-left: 20%;
  
}
.eventactive__hotevent2 {
  cursor: pointer;
  margin-top: 2px;
  margin-right: 3px;
  width: fit-content;
  height: fit-content;
  background: gold;
  color: black;
  display: flex;
  font-size: 13px;
  text-align: center;
  padding: 0px 3px;
  border-radius: 5px;
  font-weight: 500;
}
.eventactive__hotevent2 > span {
  margin-top: 4px;
  margin-right: 1px;
  background: gold;
  color: rgb(237, 0, 0);
  font-size: 13px;
}
.eventactive__hotevent {
  cursor: pointer;
  margin-top: 2px;
  margin-right: 3px;
  width: fit-content;
  height: fit-content;
  background: rgb(100, 100, 100);
  color: rgb(255, 255, 255);
  display: flex;
  font-size: 13px;
  text-align: center;
  padding: 0px 3px;
  border-radius: 5px;
  font-weight: 500;
}
.eventactive__hotevent > span {
  margin-top: 4px;
  margin-right: 1px;
  background: rgb(100, 100, 100);
  color: rgb(255, 255, 255);
  font-size: 13px;
}
.eventactive__not {
  font-size: 100%;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0);
  height: fit-content;
  margin-top: 5px;
}

.eventactive__filter {
  display: flex;
  justify-content: space-between;
  font-size: 150%;
  background: rgb(0, 0, 0);
  color: #fff;
  box-shadow: 0 1px 4px rgba(0,0,0,0.4);
  height: fit-content;
  padding: 5px 0px;
  margin-bottom: 1px;
}

.eventactive__filter > span {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.eventactive__filter > .icon {
  color: #ffffff;
}
.eventactive__filter > .icon2 {
  color: gold;
}

.eventactive__event {
  overflow-y: scroll;
  max-height: 555px;
}

.select__filter {
  margin-left: 8px;
  width: 80px;
}

.userscreen__loading {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #000000; /* Blue */
  border-bottom: 16px solid #fcca00; /* Blue */
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 30%;
  left: 45%;
  z-index: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.userscreen__loadtext {
  font-size: 150%;
  position: absolute;
  top: 20%;
  left: 46%;
  z-index: 500;
  /*
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;*/
}

.userscreen__loading2 {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #000000; /* Blue */
  border-bottom: 16px solid #fcca00; /* Blue */
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 36%;
  left: 46%;
  z-index: 500;
}


.userscreen__loadtext2 {
  font-size: 150%;
  position: absolute;
  top: 26%;
  left: 48%;
  z-index: 500;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

@media (max-width: 960px) { 

  .userscreen__loading {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    border-bottom: 5px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 30%;
    left: 47%;
    z-index: 500;
    margin-top: 30px;
  }
  .userscreen__loadtext {
    display: none;
  }
  .userscreen__loading2 {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    border-bottom: 5px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 36%;
    left: 48%;
    z-index: 500;
    margin-top: 30px;
  }
  .userscreen__loadtext2 {
    display: none;
  }
    .userscreen 
    {
    display:flex;
    max-width:500px;
    justify-content: center;

    } 
    .userscreen__primary 
    {
      flex: 1;
      width: 100%; 
      background: rgb(19, 18, 18);
      height: fit-content;
      border-radius: 5px; 
      
      
    }
    .userscreen__secondary {
      flex:0;
      opacity: 0;
      display: none;
      
    }
  
    
    .userscreen__primary2 {
      flex:0;
      opacity: 0;
      display: none;
     
    }
    
    .userscreen__secondary2 {
      flex:1;
      width: 100%; 
      height: 600px;
      background: rgb(0, 0, 0);
      border-radius: 5px;
      flex-direction: column;
      
    }
    .userscreen__swipe__text {
      text-align: center;
      color: white;
    }
    .userscreen__swipe__text > span{
      font-size: 20px;
      color: gold;
    }
    .userscreen__swipe2__text {
      text-align: center;
      color: rgb(0, 0, 0);
    }













    .userscreen__secondary__hide {
      flex:0;
      opacity: 0;
      display: none;
    }
    .header__account 
    {
      width: 100%;
      height: fit-content;
      background-color: #000000 ;
      display: flex; 
      justify-content: space-between;
      
    }
  .account__left 
    {
      flex:0.2;
      margin-left: 10px;
      margin-right: 15px;
      justify-content: center;
      align-items: center;
    }
  .account__pic 
    {
      display: block;
      margin-top: 8px;
      object-fit:cover;
      width: 90px;
      height: 90px;
      overflow:hidden;
      cursor: pointer;  
      border-radius:50%;
      box-shadow: 4px 2px 7px gold;
    }

    .account__center {
      flex: 0.95;
      display: flex; 
      flex-direction: column;
      margin-right: 5px;
      
    }
    .account__stat {
      display: flex; 
      justify-content: space-between;
      text-align: right;
      width: 100%;
    }
  
    .account__name {
      font-size: 20px;
      text-align: right;
      color: #fff;
      margin-right: 25px;
    }
    .account__id {
      font-size: 13px;
      color: rgb(228, 232, 8);
    }
    .account__nameplate {
      display: flex;
      flex-direction: column;
      text-align: right;
    }

    .account__follower {
      margin-left: 20px;
      
    }
  
    .account__follower > h2 {
      font-size: 20px;
      color: #fff;
     
      cursor: pointer;
      
    }
    .account__follower > p {
      font-size: 15px;
      color: #fff;
     
    }
  
    .account__following {
      margin-left: 20px;
      
    }
  
    .account__following > h2 {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      
    }
    .account__following > p {
      font-size: 15px;
      color: #fff;
      margin-left: 5px;
    }
  
    .account__event {
      margin-left: 5px;
      
    }
  
    .account__event > h2 {
      font-size: 20px;
      color: #fff;
      
      
    }
    .account__event > p {
      font-size: 15px;
      color: #fff;
      margin-left: 5px;
     
    }
    .account__right {
      flex:0.05;
    }
  
     .account__toggle {
      display: none;
    }
    
    .account__relation  {
      position: absolute;
      top: -1px;
      right: 5px;
      background: transparent;
      border-radius: 50%;
      padding: 1px 2px;
      
    }
    .account__relation > span {
      font-size: 140%;
    }
    
    .account__relation > .icon__friend {
      position:absolute;
      top: -10px;
      right: 5px;
      cursor:default;
      color: gold;
      font-size: 100%;
    }
    .account__relation > .icon2__favourite {
      position:absolute;
      top: -10px;
      right: 25px;
      cursor:default;
      color: gold;
      font-size: 100%;
    }
    .account__relation > .icon__favourite {
      position:absolute;
      top: -10px;
      right: 25px;
      cursor:default;
      color: rgb(255, 255, 255);
      font-size: 100%;
    }
    .account__public{
      width: fit-content;
      position:absolute;
      top: 10px;
      left: 80px;
      height: fit-content;
      background: rgb(30, 92, 208);
      color: #ffffff;
      display: flex;
      margin-right: 10px;
      padding: 0px 1px;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 70%;
      font-weight: 500;
      text-align: left;
      
    }
    .account__public > div{
      margin-left: 2px;
    }
    .account__public > span{
      margin-left: 2px;
      margin-right: 2px;
      margin-top: 4px;
      font-size: 70%;
    }
  
    .account__followbtn {
      cursor: pointer;
    }
    .eventactive__not {
      font-size: 100%;
      text-align: center;
      font-family: 'Lato', sans-serif;
      color: rgb(255, 255, 255);
      height: fit-content;
    }
}