
.maindrawer { 
    width: 100%; 
    height: 100vh;
    background: rgb(196, 189, 189);
    position: fixed;
    top:48px;
    right:0;
    z-index: 50;
    visibility: hidden;
    opacity: 0;
    /*transform: translateY(-500%); */
    transition: all 9s ;
    
    /*justify-content: center;*/
    
    
    
} 

.maindrawer.show {
    visibility: visible;
    opacity: 1;
}

.mainscreen__main {
    
    display: flex;
    flex-direction: column;
    
}
.mainscreen__top {
    flex: 0.9;
    display: flex;
    flex-direction: row;
    
}
.mainscreen__foot {
    flex: 0.1;
    display: flex;
    justify-content: space-between;
    
    margin-left: 100px;
    margin-right: 100px;
    font-size: 150%;
}


.mainscreen__left {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    flex: 0.75;
    
}

.mainscreen__right {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 0.25;
    background-color: rgb(14, 14, 14);
    
}

.mainscreen__pic {
    
    width: 100%;
    height: 100%;
    /*border-radius: 20px; */
}



.mainscreen__message {
    font-size: 320%;
    font-weight: 500;
    position: absolute; 
    top: 72%; 
    left: 40px; 
    color: white;
    letter-spacing: 4px;  
   background: rgb(0, 0, 0); /* fallback color */
   background: rgba(46, 46, 46, 0.7);
   padding: 7px; 
   
}
.mainscreen__message__dream {
    font-size: 320%;
    font-weight: 500;
    position: absolute; 
    top: 51%; 
    left: 811px; 
    color: gold;
    letter-spacing: 4px;  
    
    /*
   background: rgb(0, 0, 0); /* fallback color 
   background: rgba(46, 46, 46, 0.7);
   padding: 5px;  */
}
.mainscreen__message__love {
    font-size: 300%;
    font-weight: 500;
    position: absolute; 
    top: 58%; 
    left: 858px; 
    color: rgb(255, 255, 255);
    letter-spacing: 4px;  
    
    /*
   background: rgb(0, 0, 0); /* fallback color 
   background: rgba(46, 46, 46, 0.7);
   padding: 5px;  */
}
.mainscreen__message__like {
    font-size: 220%;
    font-weight: 500;
    position: absolute; 
    top: 65.5%; 
    left: 811px; 
    color: rgb(255, 255, 255);
    letter-spacing: 4px;  
    
    /*
   background: rgb(0, 0, 0); /* fallback color 
   background: rgba(46, 46, 46, 0.7);
   padding: 5px;  */
}

.mainscreen__box {
    width: 25%;
    height: 86%;
    position: absolute; 
    top: 4%; 
    right: 80px; 
    letter-spacing: 4px;  
   /*background: rgb(0, 0, 0); /* fallback color */
   background: rgb(0, 0, 0,0.75); 
   padding: 5px;  
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   font-family: 'Lato', sans-serif;

   
}

.mainscreen__area1 {
    flex: 0.65;
    margin: 3px 3px;
    position:relative;
    padding-top:5px;
    display:inline-block;
}
.mainscreen__logo {
    display: block;
    margin: 1% 25%;
    /*object-fit:cover;*/
    width: 50%;
    height: 50%;
    overflow:hidden;
    
    
  }
  .mainscreen__area1 > h1 {
    font-size: 200%;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 3px;  
    text-align: center;
    margin-bottom: 5%;
}
.mainscreen__area1 > h2 {
    font-size: 150%;
    font-weight: 700;
    margin-left: 10%;
    
    margin-bottom: 2%;
    color: gold
}


.mainscreen__area1 > h3 {
    margin-left: 12%;
    font-size: 130%;
    margin-bottom: 2%;
    color: rgb(255, 255, 255);
}
.mainscreen__area1 > h4 {
    font-size: 130%;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 3px;  
    text-align: center;
    
}

.mainscreen__area2 {
    flex: 0.35;
}
.mainscreen__area2 > h2 {
    font-size: 180%;
    font-weight: 700;
    margin-left: 10%;
    margin-top: 12%;
    margin-bottom: 3%;
    color: gold
}

.mainscreen__area2 > h3 {
    margin-left: 15%;
    letter-spacing: 2px;  
    font-size: 120%;
    margin-bottom: 3%;
    color: rgb(255, 255, 255);
}




@media (max-width: 960px) { 
    .maindrawer { 
        width: 100%; 
        height: 100%;
        background: rgb(0, 0, 0);
        position: fixed;
        top:48px;
        left:0;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s ;
        justify-content: center;
        

    }  
    .maindrawer.show {
        visibility: visible;
        opacity: 1;
    }
    .mainscreen__pic {
        display: none;
    }
    .mainscreen__message, .mainscreen__message__dream, .mainscreen__message__like ,.mainscreen__message__love,.mainscreen__area2, .mainscreen__logo {
        display: none;
    } 
    .mainscreen__box {
        width: 100%;
        height: 100%;
        position: absolute; 
        top: 4%; 
        left: 0%; 
        letter-spacing: 4px;  
       
       background: rgb(0, 0, 0,0.75); 
       
       border-radius: 20px;
       display: flex;
       flex-direction: column;
       font-family: 'Lato', sans-serif;
    
       
    }
    .mainscreen__login {
        width: 100%;
        margin-top: 10px;
        
        margin-bottom: 10px;
        justify-content: center;
        display: flex;
        
    }
    .mainscreen_login_btn {
        text-align: center;
        width: fit-content;
        height: 40px;
        justify-content: center;
        
        
    }
    .mainscreen__area1 {
        width: 100%;
        margin: 3px 3px;
        padding-top:5px;
    }
   
    .mainscreen__area1 > h1 {
        font-size: 180%;
        font-weight: 700;
        color: rgb(255, 255, 255);
        letter-spacing: 3px;  
        text-align: center;
        margin-bottom: 5%;
    }
    .mainscreen__area1 > h2 {
        font-size: 120%;
        font-weight: 700;
        margin-left: 0;
        text-align: center;
        margin-bottom: 2%;
        color: gold
    }
    
    
    .mainscreen__area1 > h3 {
        text-align: center;
        font-size: 100%;
        margin-left: 0;
        margin-bottom: 2%;
        color: rgb(255, 255, 255);
    }
    .mainscreen__area1 > h4 {
        font-size: 130%;
        font-weight: 700;
        color: rgb(255, 255, 255);
        letter-spacing: 3px;  
        text-align: center;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .mainscreen__area1 > h6 {
        margin-top: 5px;
        font-size: 110%;
        font-weight: 700;
        
        color: rgb(255, 255, 255);
        
        text-align: center;
    }
    .mainscreen__area1 > h5 {
        margin-top: 5px;
        font-size: 140%;
        font-weight: 700;
        color: rgb(96, 181, 0);
        letter-spacing: 3px;  
        text-align: center;
    }
    .mainscreen__mobile {
        width: 100%;
        margin-top: 20px;
        
       
        justify-content: center;
        display: flex;
        
    }
    .mainscreen__appstore {
        /*object-fit:cover;*/
        width: 150px;
        height: 45px;
        
    }
    .mainscreen__googlestore {
        /*object-fit:cover;*/
        width: 150px;
        height: 45px;
        
    }
    
    
        
}