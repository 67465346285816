
.register { 
    
    background: rgb(255, 255, 255);
    position:fixed;
    top:11%;
    left:38%;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    /*transform: translateY(-500%); */
    transition: all 0.3s ;
    border-radius: 10px;
    /*justify-content: center;*/
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
} 

.register.show {
    visibility: visible;
    opacity: 1;
    /*transform: translateY(0);*/
}

.register__title {
    height: 40px;
    font-size: 18px;
    padding: 5px 5px;
    background: rgb(191, 191, 191);
    color: rgb(0, 0, 0);
    text-align: center;
}

.register__main {
    
    width: 350px;
    display: flex;
    flex-direction: column;
    height: 550px;
}
.register__main2 {
    
    width: 350px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    
}

.register__area1 {
    flex: 0.4;
    margin: 3px 3px;
    position:relative;
    padding-top:5px;
    display:inline-block;
}
.register__pic {
    display: block;
    margin: 20px 85px;
    object-fit:cover;
    width: 180px;
    height: 180px;
    overflow:hidden;
    border-radius: 125px;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
  }
  .register__pic__badge {
    position: absolute;
    right:25px;
    bottom:15px;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:rgb(0, 0, 0);
    padding: 1px 10px;
    font-size:15px;
    z-index:10;
    cursor: pointer;
}
.register__pic__badge:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

.register__area2 {
    flex: 0.12;
}
.register__name {
    margin: 5px 10px;
}

.register__area3 {
    flex: 0.12;
}


.register__area4 {
    flex: 0.12;
}
.register__email {
    margin: 5px 10px;
}

.register__area5 {
    flex: 0.12;
    text-align: center;
    display: flex;
    flex-direction: row;
}
.register__title__ex {
    flex: 0.21;
    background: cornsilk;
    margin: 15px 5px;
}
.register__example__ok {
    flex: 0.79;
    padding: 5px 5px;
    background: black;
    color: gold;
    margin: 10px 5px;
    border-radius: 10px;
    font-size: 110%;
}
.register__example__ng {
    flex: 0.79;
    padding: 5px 5px;
    background: black;
    color: red;
    margin: 10px 5px;
    border-radius: 10px;
    font-size: 110%;
}
.register__link {
    margin: 5px 10px;
}

.register__area6 {
    flex: 0.12;
}
.register__btn {
    margin: 10px 10px;
}

.register__area6 > h1 {
    text-align: center;
}
.register__area6 > h2 {
    font-size: 120%;
    text-align: center;
}

@media (max-width: 960px) { 
    .register { 
        width: 100%; 
        height: 520px;
        background: rgb(255, 255, 255);
        position:fixed;
        top:0;
        left:0;
        z-index: 300;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ;
        border-radius: 10px;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
        justify-content: center;
    } 
    .register.show {
        visibility: visible;
        opacity: 1;
    }
    .register__main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
    }
    .register__area1 {
        flex: 0.2;
        margin: 3px 3px;
        padding-top:5px;
        display:inline-block;
    }
    .register__pic {
        display: block;
        object-fit:cover;
        width: 125px;
        height: 125px;
        overflow:hidden;
        border-radius: 50%;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
        margin-left: auto;
        margin-right: auto;
      }
      .register__pic__badge {
        height: fit-content;
        background:gold;
        right:0;
        top:15px;
        border-radius: 20px;
        color:rgb(0, 0, 0);
        padding: 1px 10px;
        font-size:15px;
        z-index:10;
        cursor: pointer;
    }
    .register__pic__badge:hover {
        transform: scale(1.10);
        transition: .25s ease-out;
    }
    .register__btn {
        margin: 2.5px 10px;
    }
    .register__link {
        margin: 2.5px 10px;
    }
    
    .register__main2 {
    
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        
    }

}