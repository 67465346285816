.createwith__detail {
    display: flex;
    margin: 5px 5px;
    background: rgb(255, 255, 255);
    
    border-radius: 5px;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
    cursor: pointer; 
}

.createwith__detail:hover {
    transform: scale(1.03);
    transition: .25s ease-out;
    background: rgb(0, 0, 0);
    color: white;
}
.createwith__picarea {
    flex: 0.25;
}

.createwith__pic {
    
    margin: 5px 5px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
}



.createwith__name {
    flex: 0.75;
    margin-top: 12px;
    margin-left: 5px;
    
}
@media (max-width: 960px) { 
    .createwith__detail {
        display: flex;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        width: 80%;
        height: 60px;
        border-radius: 5px;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
        cursor: pointer; 
    }
    .createwith__picarea {
        flex: 0.25;
    }
    .createwith__pic {
        
        margin: 5px 5px;
        display: block;
        width: 50px;
        height: 50px;
        object-fit: cover;
        overflow:hidden;
        border-radius: 50%;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
    }
    .createwith__name {
        flex: 0.75;
        margin-top: 15px;
        margin-left: 15px;
        
    }
}
