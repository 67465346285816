@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.event__calendar{
    display: flex;
    margin-top: 1px;
    margin-bottom: 1px;
    color: rgb(3, 76, 213);
    background: gold;
}

.event__calendar > span {
    font-size: 15px;
    margin-left: 1px;  
}

.event__calendar > p {
    font-size: 10px;
    margin-left: 2px;  
}
