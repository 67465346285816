.invite__pic {
    margin-top: 10px;
    margin-left: 8px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%; 
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
}

.inviteuser__pic {
    position:relative;
    /*padding-top:20px;*/
    display:inline-block;
}

.invite__cancle{
    position: absolute;
    right:3px;
    top:5px;
    background:red;
    text-align: center;
    border-radius: 20px;
    color:white;
    padding: 1px 4.5px;
    font-size:9px;
    font-weight: 1000;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgb(0, 0, 0);
}

.invite__cancle:hover {
    transform: scale(1.30);
    transition: .25s ease-out;
}