/* VARIABLES */

:root {
    --main-color: #0e0f10;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }

/* SCHEDULE */

.schedule__calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    
  }  


.header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 2.0em;
    height: 3em;
    border-bottom: 1px solid var(--border-color);
    align-items: center;
  }
  
.header .icon {
    cursor: pointer;
    transition: .15s ease-out;
  }
  
.header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: #0e0f10;
  }
  
.header .icon:first-of-type {
    margin-left: 1em;
  }
  
.header .icon:last-of-type {
    margin-right: 1em;
  }
  
.days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 90%;
    padding: .75em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
.body .cell { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
    position: relative;
    height: 5.2em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
  .body .cellh { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
    position: relative;
    height: 5.2em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: rgb(246, 246, 246);
    transition: 0.25s ease-out;
  }

  .body .hide {
    position: relative;
    height: fit-content;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
 

  
.body .cell:hover { /* BG ตอนเอา Mouse ไปจ่อที่ปฏิทิน*/
    background:var(--bg-color);
    transition: 0.5s ease-out;
  }
  .body .cellh:hover { /* BG ตอนเอา Mouse ไปจ่อที่ปฏิทิน*/
    background:var(--bg-color);
    transition: 0.5s ease-out;
  }
  .body .hide:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }
  
.body .selected { /* style ของวันปัจจุบัน*/
    border-left: 6px solid transparent;
    border-image: linear-gradient(45deg, #29a200e0 0%,#000000 40%);
    border-image-slice: 1;
    background: rgb(0, 0, 0);
    color:white;
    font-size: 100%;
    
  }

  
  
.body .row {
    border-bottom: 1px solid var(--border-color);
  }
  
.body .row:last-child {
    border-bottom: none;
  }
  
.body .cell:last-child {
    border-right: none;
  }
  .body .cellh:last-child {
    border-right: none;
  }
  
.body .cell .number { /* style ของเลขวันที่ในแต่ละวัน*/
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .1em;
    right: .2em;
    font-weight: 700;
    background: #fff;
    
    padding: 0px 2px;
    
  }
  .body .cellh .number { /* style ของเลขวันที่ในแต่ละวัน*/
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .1em;
    right: .2em;
    font-weight: 700;
    background:rgb(246, 246, 246);
    
    padding: 0px 2px;
    
  }
  

  .body .hide .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .1em;
    right: .2em;
    font-weight: 700;
    background:#fff;
    padding: 0px 2px;
  }
  .body .selected .number{ 
   
    padding: 0px 2px;
    background: gold;
    color:black;
    font-size: 80%;
    
    font-weight: 500;
    
  }
.body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
.body .cell .bg { /* [Important] BG ของในแต่ละวัน*/
    font-weight: 700;
    line-height: 1;
    color:rgb(130, 130, 130);
    opacity: 0;
    font-size: 2em;
    position: absolute;
    top: 1.4em;
    right: .0em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }
  .body .cellh .bg { /* [Important] BG ของในแต่ละวัน*/
    font-weight: 700;
    line-height: 1;
    color:rgb(130, 130, 130);
    opacity: 0;
    font-size: 2em;
    position: absolute;
    top: 1.4em;
    right: .0em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }

  .body .selected:hover { /* [Important] BG ของในแต่ละวัน*/
    color:rgb(0, 0, 0);
  }

  .body .hide .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 7em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }

.body .cell:hover .bg,.schedule .body .selected .bg  { /* ทำให้ตัวเลขจางๆ*/
    opacity: 0.6;
    transition: .5s ease-in;
  }
  .body .cellh:hover .bg,.schedule .body .selected .bg  { /* ทำให้ตัวเลขจางๆ*/
    opacity: 0.6;
    transition: .5s ease-in;
  }
.body .hide:hover .bg,.schedule .body .selected .bg  {
    opacity: 0.05;
    transition: .5s ease-in;
  }

 


  
.body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
  }

  /* GRID */

 .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  



  @media (max-width: 960px) { 
    .days {
      display: none;
    }
    .body .col {
      flex-grow: 0;
      flex-basis: calc(100%/1);
      width: calc(100%/1);
    }
    .body .cell .number { /* style ของเลขวันที่ในแต่ละวัน*/
      position: absolute;
      font-size: 82.5%;
      line-height: 1;
      top: .1em;
      right: .2em;
      font-weight: 700;
    }
    .body .cellh .number { /* style ของเลขวันที่ในแต่ละวัน*/
      position: absolute;
      font-size: 82.5%;
      line-height: 1;
      top: .1em;
      right: .2em;
      font-weight: 700;
    }
    .body .cell { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      position: relative;
      height: 3em;
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: var(--neutral-color);
      /*transition: 0.5s ease-out;*/
    }
    .body .cellh { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      position: relative;
      height: 3em;
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: rgb(246, 246, 246);
      /*transition: 0.5s ease-out;*/
    }
    .body .hide {
      position: relative;
      height: fit-content;
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: var(--neutral-color);
      /*transition: 0.5s ease-out;*/
    }
   
    .body .cell:hover { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      transform: scale(1.02);
      transition: .25s ease-out;
      
    }
    .body .cellh:hover { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      transform: scale(1.02);
      transition: .25s ease-out;
      
    }
    
  .body .cell {
      border-right: 1px solid var(--border-color);
      border-bottom: 3px solid var(--border-color);
      
    }
    .body .disabled {
      display: none;
    }
    .body .selected { /* style ของวันปัจจุบัน*/
      border-left: 6px solid transparent;
      border-image: linear-gradient(45deg, #29a200e0 0%,#000000 40%);
      border-image-slice: 1;
      background: rgb(0, 0, 0);
      color:rgb(255, 255, 255);
      
      
    }
  }