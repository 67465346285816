.sidedrawer { 
    width: 350px; 
    height: fit-content;
    
    position: absolute;
    
    

    left:0%;
    /*
    top:150px;
    /*
    visibility: hidden;
    opacity: 0;
    */
    z-index: 200;
    transform: translateX(-100%); 
    border-radius: 10px;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    transition: all 0.3s ;
} 

.sidedrawer.show {
    /*
    visibility: visible;
    opacity: 1;*/
    transform: translateX(0);
}


.sidedrawer__all {
    display: flex;
    flex-direction: column;
    list-style: none;
    /*height: 669px;*/
}







.sidedrawer__eventamount {
    margin-bottom: 10px;
    font-size: 140%;
    text-align: center;
}

.sidedrawer__title { 
    color: white;
    background: black;
    
    font-size: 140%;
    text-align: center;
}
.sidedrawer__not {
    width: 100%;
    font-size: 110%;
    background: rgba(237, 237, 237, 0.25);
    color: white;
    text-align: center;
}
/*
.sidedrawer__event { 
    overflow-y: scroll;
    max-height: 555px;
}
*/

@media (max-width: 960px) { 
    .sidedrawer { 
        width: 350px; 
        height: fit-content;
        
        position: absolute;
        
        left:0%;
        
/*
        top:150px;
        /*
        visibility: hidden;
        opacity: 0;
        */
        z-index: 200;
        transform: translateX(-100%); 
        border-radius: 10px;
        padding-top: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;
        transition: all 0.3s ;
    } 
    
    .sidedrawer.show {
        /*
        visibility: visible;
        opacity: 1;*/
        transform: translateX(0);
    }
    
    
    .sidedrawer__all {
        display: flex;
        flex-direction: column;
        list-style: none;
        /*height: 669px;*/
    }
    
    
}

