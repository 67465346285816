.accountsettingdrawer { 
    visibility: hidden;
    opacity: 0;
} 

.accountsettingdrawer.show {
    visibility: visible;
    opacity: 1;
}

.accountsetting__main {
    z-index: 40;
    color: rgb(0, 0, 0);
    margin-top: 2px;
    background:rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    font-size: 85%;
    padding: 4px 6px;
    text-align: left;
    position: absolute;
    width: 180px;
    left:0px;
    top:inherit;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.4);
}
.accountsetting__main > div {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 2px;
    font-weight: 500;

}
.accountsetting__main > div:hover {
    color: rgb(0, 0, 0);
   background: rgb(223, 223, 223);
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
    
    
}


.accountsetting__public > span {
    font-weight: 700;
    margin-bottom: 2px;
}

.accountsetting__private > span {
    font-weight: 700;
    margin-bottom: 2px;
}

@media (max-width: 960px) { 
    .accountsetting__main {
        z-index: 40;
        color: rgb(0, 0, 0);
        margin-top: 2px;
        background:rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        font-size: 85%;
        padding: 4px 6px;
        text-align: left;
        position: absolute;
        width: 130px;
        left:0px;
        top:inherit;
        border-radius: 5px;
        box-shadow: 0 4px 4px rgba(0,0,0,0.4);
    }
}
