.navbar { 
    width: 100%; 
    height: 40px;
    background-color: #000000 ;
    display: flex; 
    align-items: center;
    padding: 1.5rem 1rem;
    justify-content: space-between;
    z-index: 50;
}


.Logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .nav__pic {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    border-radius: 20px;
    cursor: pointer;  
    display: block;
    object-fit:cover;
    overflow:hidden;
  }


.navbar__logo h2 { /* ใส่ Font ให้ MERN Shopping Cart */
    color: #f4f4f4;
    font-size: 1.4rem;
    cursor: pointer;  
    letter-spacing: 1px;  
    font-family: 'Lato', sans-serif;
}

.navbar__logo h1 { 
    color: #5a8c0a;
    font-size: 1rem;
    margin-left: 10px;
    margin-top: 8px;
}

.navbar__login > h2 { 
    color: #f4f4f4;
    font-size: 1rem;
    /*margin-left: 10px;
    margin-top: 8px; */
    align-items: center;
    justify-content: center;
}

.navbar__logo > a { 
    text-decoration: none;
    display: flex; 
}



.navbar__logo { 
    display: flex; 
    align-items: center;
}

.navbar__right { 
    display: flex; 
    align-items: center;
}

.navbar__search {
    margin-right: 15px;
    font-size: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 0.5px 15px;   
    
}


.navbar_login_btn {
    width: 70px;
    height: 30px;
}



@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }
  .iconlove {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }

.navbar__logo #logo {
    font-size: 200%;
    text-align: center;
    color: #ffffff;
    line-height: 1;
  }

.navbar__logo #logo .icon {
    cursor:pointer;
    margin-right: .5em;
}

.navbar__right #logo {
    display: flex;
    font-size: 200%;
    text-align: center;
    color: #ffffff;
    line-height: 1;
  }

.navbar__right #logo .icon {
    cursor:pointer;
    margin-right: .5em;
}

.navbar__right #logo .iconlove {
    cursor:pointer;
    margin-right: .4em;
    color: #e43b68;
    font-size: 130%;
    text-align: center;
    line-height: 1;
}

.followreq__logo {
    position:relative;
    display:inline-block;
}

.followreq__noti{
    position: absolute;
    right:5px;
    top:0px;
    background:rgb(25, 123, 0);
    text-align: center;
    border-radius: 20px;
    color:white;
    padding: 4px 8px;
    font-size:11px;
    font-weight: 1000;
}

.eventwithreq__logo {
    position:relative;
    display:inline-block;
}

.eventwithreq__noti{
    position: absolute;
    right:5px;
    top:0px;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:rgb(0, 0, 0);
    padding: 4px 8px;
    font-size:11px;
    font-weight: 1000;
    
}

@media (max-width: 960px) { 
    
    .navbar__logo h2 {
        display: none;
    }
    .eventwithreq__logo, .followreq__logo {
        font-size: 80%;
    }
    .navbar__right #logo .icon {
        cursor:pointer;
        margin-right: 5px;
    }
    .followreq__noti{
        position: absolute;
        right:0px;
        top:-2px;
        background:rgb(25, 123, 0);
        text-align: center;
        border-radius: 20px;
        color:white;
        padding: 3px 6px;
        font-size:7px;
        font-weight: 1000;
    }
    .eventwithreq__noti{
        position: absolute;
        right:0px;
        top:-2px;
        background:gold;
        text-align: center;
        border-radius: 20px;
        color:rgb(0, 0, 0);
        padding: 3px 6px;
        font-size:7px;
        font-weight: 1000;
        
    }
    .navbar__login {
        margin-right: 5px;
    }
    .navbar__logo #logo {
        display: none;
    }
    .navbar__login > .navbar_login_btn {
        display: none;
    }
    .navbar__search {
        font-size: 50%;
        background-color: white;
        border-radius: 10px;
        padding: 0.5px 10px;   
    }
    .navbar {
        flex: 1;
        width: 100%; 
        height: 40px;
        background-color: #000000 ;
        display: flex; 
        align-items: center;
        padding: 1.5rem 0rem;
        justify-content: space-between;
        z-index: 50;
    }
    .Logo {
        margin-right: 5px;
        cursor: pointer;
      }
      .navbar__search {
        margin-right: 5px;
        font-size: 80%;
        background-color: white;
        border-radius: 10px;
        padding: 0.5px 10px;   
      }
    
}