.item__detail {
    display: flex;
    margin: 5px 10px;
    background:transparent;
    border-radius: 10px;
    border: 1px solid rgb(63, 63, 63);
    box-shadow: 3px 3px rgba(0,0,0,0.4);
    
}

.item__detail:hover {
    transform: scale(1.03);
    transition: .25s ease-out;
    background: rgb(56, 56, 56);
}

.item__left {
    flex: 0.2;
}

.item__pic {
    margin: 5px 5px;
    display: block;
    height: 50px;
    width: 50px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgb(63, 63, 63);
}

.item__right {
    margin-top: 4px;
    margin-left: 5px;
    flex: 0.8;
    display: flex;
    flex-direction: column;
}

.item__name {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
}

.item__info {
    font-family: 'Lato', sans-serif;
    font-size: 85%;
}
.item__info__go {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    padding: 5px 10px;
    background: green;
    width: fit-content;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.item__info__busy {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    padding: 5px 10px;
    background: red;
    width: fit-content;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.item__info2 {
    font-family: 'Lato', sans-serif;
}

.item__title {
    margin-top: 3px;
    cursor: pointer;
}
.item__title .icon{
    margin-bottom: 2px;
    font-size: 120%;
}


.item__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.item__btnevent {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.item__btnevent > .item__go {
    margin-bottom: 5px;
}
.item__btnevent > .item__busy {
    
    margin-bottom: 5px;
}
.item__go:hover, .item__busy:hover {
    transform: scale(1.05);
    transition: .25s ease-out;
}


.item__result {
    background: rgb(116, 116, 116);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
}

.item__accept:hover, .item__decline:hover {
    transform: scale(1.1);
    transition: .25s ease-out;
}

.item__location__badge {
    margin-top: 3px;
    position: absolute;
    right:20px;
    top:inherit;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:black;
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}
.item__location__badge:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}