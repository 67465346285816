@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }


.eventactive__detail {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    display:flex;
    flex-direction: row;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    /*box-shadow: 0 1px 4px rgba(0,0,0,0.4);*/
    height: 65px;
    /*border-radius: 5px;*/
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 5px;
    width: 90%;
    box-shadow: -4px 2px 3px rgba(0, 0, 0, 0.5);
    /*box-shadow: 3px 3px rgba(0,0,0,0.4);*/
    
}

.eventactive__detail:hover {
    transform: scale(1.01);
    transition: .1s ease-out;
  } 
.eventactive__left {
    flex: 0.25;
    display:inline-block;
}
.eventactive__right {
   
    flex: 0.75;
    display: flex;
    flex-direction: column;
    box-shadow: -3px 0px 3px rgba(0,0,0,0.4);
    
    
}
.eventactive__up {
    display: flex;
    justify-content: space-between;
    margin-right: 0px;
}
.eventactive__name {
    margin-left: 5px;
    margin-top: 2px;
    font-size: 100%;
    font-weight: 700;
}
.eventactive__online {
    font-weight: 700;
    background:red;
    text-align: center;
    border-radius: 20%;
    color:gold;
    padding: 0.5px 7px;
    font-size:13px;
    z-index:10;
    box-shadow: 2px 2px 3px rgb(0, 0, 0);
    display: flex;
    height: fit-content;
    margin-top: 1px;
    margin-right: 2px;
}

.eventactive__before {
    margin-top: 1px;
    
    font-weight: 700;
    text-align: center;
    color:rgb(85, 85, 85);
    padding: 1px 7px;
    font-size:14px;
    display: flex;
}
.eventactive__before > span{
    margin-top: 1px;
    margin-left: 3px;
    font-size: 14px;
    padding: 1px 3px;
    background: gold;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    box-shadow: 1.5px 1.5px rgba(0,0,0,0.4);
}
.eventactive__info {
    margin-left: 5px;
    margin-top: 1px;
    font-size: 85%;
    width: 100%;
    
    
}

.eventactive__location .icon {
    font-size: 110%;
    color: #000000;
    margin-right: 3px;
    margin-bottom: 3px;
    
}

.eventactive__location {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    
}

.eventactive__location > div{
    margin-top: 1px;
    margin-left: 5px;
    font-size: 80%;
}



.eventactive__pic {
    display: block;
    margin: 5px 10px;
    object-fit:cover;
    width: 56px;
    height: 56px;
    overflow:hidden;
    border-radius: 50%;
    box-shadow: -3px -2px 3px rgba(0,0,0,0.4);
    cursor: pointer;
    z-index: 20;
  }

  .eventactive__map {
    margin-top: 3px;
    position: relative;
    margin-right: 5px;
    background:gold;
    text-align: center;
    border-radius: 5px;
    color:black;
    padding: 1px 2px;
    font-size:11px;
    transform: scale(1.05);
    transition: .25s ease-out;
    cursor: pointer;
    box-shadow: 1.5px 1.5px rgba(0,0,0,0.4);
  }
  
  .eventactive__hot__badge{
    position: absolute;
    top: inherit;
    background:rgb(197, 2, 2);
    text-align: center;
    border-radius: 20px;
    color:gold;
    padding: 2px 6px;
    font-size:10px;
    font-weight: 1000;
}
.eventactive__promo__badge{
    position: absolute;
    top: inherit;
    background:rgb(0, 0, 0);
    text-align: center;
    border-radius: 20px;
    color:gold;
    padding: 2px 5px;
    font-size:8px;
    font-weight: 1000;
}


  