.withuser__pic {
    
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    position:relative;
    padding-top:5px;
    display:inline-block;
}
.with__pic__accept {
    margin-left: 3px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 20px;
    /*border: 0.5px solid rgb(34, 169, 0);*/
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
    cursor: pointer;  
}

.withuser__pic:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

.with__pic__accept__badge {
    position: absolute;
    right:0px;
    bottom:-5px;
    background:rgb(0, 135, 16);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
}

.with__pic__reject {
    filter:contrast(50%);
    margin-left: 3px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 20px;
    /*border: 1px solid rgb(175, 57, 2);
    box-shadow: 0 0 3px 4px rgb(118, 118, 118);*/
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
    cursor: pointer;  
}

.with__pic__reject__badge {
    position: absolute;
    right:0px;
    bottom:-5px;
    background:rgb(175, 57, 2);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
}




.with__pic__request {
    filter: contrast(100%);
    margin-left: 3px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 20px;
    /*border: 3px solid rgb(0, 0, 0);
    box-shadow: 0 0 3px 4px rgb(118, 118, 118);*/
    box-shadow: 4px 2px 3px rgb(2, 2, 2);
    cursor: pointer;  
}

.with__pic__wait {
    position: absolute;
    right:0px;
    bottom:-5px;
    background:rgb(0, 0, 0);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
}