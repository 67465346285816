@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Lato&family=Open+Sans&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
}

body {
    font-family: 'Oswald', sans-serif;
    background: rgb(196, 189, 189);
    
}
/*
img {
    width: 100%;
    object-fit: contain;
}*/
@media (max-width: 960px) { 
    html,body {
        font-family: 'Oswald', sans-serif;
        background: rgb(0, 0, 0);
        width: 100%;
        position: absolute;
        overflow-x: hidden;
    }
    
    
   
}
