.searchdrawer { 
    width: 300px; 
    height: 90%;
    background: rgb(38, 38, 38);
    position: fixed;
    top: 50px;
    right: 0%;
    z-index: 100;
    transform: translateX(+100%); 
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    border-radius: 10px;
    overflow-y: scroll;
    
} 

.searchdrawer.show {
    transform: translateX(0);
}

.searchdrawer__not {
    text-align: center;
    font-size: 100%;
    color: white;
}

.searchdrawer__title {
    
    height: 34px;
    
    font-size: 17px;
    padding: 3px 3px;
    background: gold;
    color: rgb(0, 0, 0);
    text-align: center;
    
}
@media (max-width: 960px) { 
    .searchdrawer { 
        width: 250px; 
        height: 90%;
        background: rgb(38, 38, 38);
        position: fixed;
        top: 50px;
        right: 0%;
        z-index: 500;
        transform: translateX(+100%); 
        transition: all 0.3s ;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        overflow-y: scroll;
    } 
}