@import url(https://fonts.googleapis.com/css2?family=Oswald&family=Lato&family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
}

body {
    font-family: 'Oswald', sans-serif;
    background: rgb(196, 189, 189);
    
}
/*
img {
    width: 100%;
    object-fit: contain;
}*/
@media (max-width: 960px) { 
    html,body {
        font-family: 'Oswald', sans-serif;
        background: rgb(0, 0, 0);
        width: 100%;
        position: absolute;
        overflow-x: hidden;
    }
    
    
   
}



.navbar { 
    width: 100%; 
    height: 40px;
    background-color: #000000 ;
    display: flex; 
    align-items: center;
    padding: 1.5rem 1rem;
    justify-content: space-between;
    z-index: 50;
}


.Logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .nav__pic {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    border-radius: 20px;
    cursor: pointer;  
    display: block;
    object-fit:cover;
    overflow:hidden;
  }


.navbar__logo h2 { /* ใส่ Font ให้ MERN Shopping Cart */
    color: #f4f4f4;
    font-size: 1.4rem;
    cursor: pointer;  
    letter-spacing: 1px;  
    font-family: 'Lato', sans-serif;
}

.navbar__logo h1 { 
    color: #5a8c0a;
    font-size: 1rem;
    margin-left: 10px;
    margin-top: 8px;
}

.navbar__login > h2 { 
    color: #f4f4f4;
    font-size: 1rem;
    /*margin-left: 10px;
    margin-top: 8px; */
    align-items: center;
    justify-content: center;
}

.navbar__logo > a { 
    text-decoration: none;
    display: flex; 
}



.navbar__logo { 
    display: flex; 
    align-items: center;
}

.navbar__right { 
    display: flex; 
    align-items: center;
}

.navbar__search {
    margin-right: 15px;
    font-size: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 0.5px 15px;   
    
}


.navbar_login_btn {
    width: 70px;
    height: 30px;
}

.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }
  .iconlove {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }

.navbar__logo #logo {
    font-size: 200%;
    text-align: center;
    color: #ffffff;
    line-height: 1;
  }

.navbar__logo #logo .icon {
    cursor:pointer;
    margin-right: .5em;
}

.navbar__right #logo {
    display: flex;
    font-size: 200%;
    text-align: center;
    color: #ffffff;
    line-height: 1;
  }

.navbar__right #logo .icon {
    cursor:pointer;
    margin-right: .5em;
}

.navbar__right #logo .iconlove {
    cursor:pointer;
    margin-right: .4em;
    color: #e43b68;
    font-size: 130%;
    text-align: center;
    line-height: 1;
}

.followreq__logo {
    position:relative;
    display:inline-block;
}

.followreq__noti{
    position: absolute;
    right:5px;
    top:0px;
    background:rgb(25, 123, 0);
    text-align: center;
    border-radius: 20px;
    color:white;
    padding: 4px 8px;
    font-size:11px;
    font-weight: 1000;
}

.eventwithreq__logo {
    position:relative;
    display:inline-block;
}

.eventwithreq__noti{
    position: absolute;
    right:5px;
    top:0px;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:rgb(0, 0, 0);
    padding: 4px 8px;
    font-size:11px;
    font-weight: 1000;
    
}

@media (max-width: 960px) { 
    
    .navbar__logo h2 {
        display: none;
    }
    .eventwithreq__logo, .followreq__logo {
        font-size: 80%;
    }
    .navbar__right #logo .icon {
        cursor:pointer;
        margin-right: 5px;
    }
    .followreq__noti{
        position: absolute;
        right:0px;
        top:-2px;
        background:rgb(25, 123, 0);
        text-align: center;
        border-radius: 20px;
        color:white;
        padding: 3px 6px;
        font-size:7px;
        font-weight: 1000;
    }
    .eventwithreq__noti{
        position: absolute;
        right:0px;
        top:-2px;
        background:gold;
        text-align: center;
        border-radius: 20px;
        color:rgb(0, 0, 0);
        padding: 3px 6px;
        font-size:7px;
        font-weight: 1000;
        
    }
    .navbar__login {
        margin-right: 5px;
    }
    .navbar__logo #logo {
        display: none;
    }
    .navbar__login > .navbar_login_btn {
        display: none;
    }
    .navbar__search {
        font-size: 50%;
        background-color: white;
        border-radius: 10px;
        padding: 0.5px 10px;   
    }
    .navbar {
        flex: 1 1;
        width: 100%; 
        height: 40px;
        background-color: #000000 ;
        display: flex; 
        align-items: center;
        padding: 1.5rem 0rem;
        justify-content: space-between;
        z-index: 50;
    }
    .Logo {
        margin-right: 5px;
        cursor: pointer;
      }
      .navbar__search {
        margin-right: 5px;
        font-size: 80%;
        background-color: white;
        border-radius: 10px;
        padding: 0.5px 10px;   
      }
    
}
.backdrop {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
}
.sidedrawer { 
    width: 350px; 
    height: -webkit-fit-content; 
    height: -moz-fit-content; 
    height: fit-content;
    
    position: absolute;
    
    

    left:0%;
    /*
    top:150px;
    /*
    visibility: hidden;
    opacity: 0;
    */
    z-index: 200;
    transform: translateX(-100%); 
    border-radius: 10px;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    transition: all 0.3s ;
} 

.sidedrawer.show {
    /*
    visibility: visible;
    opacity: 1;*/
    transform: translateX(0);
}


.sidedrawer__all {
    display: flex;
    flex-direction: column;
    list-style: none;
    /*height: 669px;*/
}







.sidedrawer__eventamount {
    margin-bottom: 10px;
    font-size: 140%;
    text-align: center;
}

.sidedrawer__title { 
    color: white;
    background: black;
    
    font-size: 140%;
    text-align: center;
}
.sidedrawer__not {
    width: 100%;
    font-size: 110%;
    background: rgba(237, 237, 237, 0.25);
    color: white;
    text-align: center;
}
/*
.sidedrawer__event { 
    overflow-y: scroll;
    max-height: 555px;
}
*/

@media (max-width: 960px) { 
    .sidedrawer { 
        width: 350px; 
        height: -webkit-fit-content; 
        height: -moz-fit-content; 
        height: fit-content;
        
        position: absolute;
        
        left:0%;
        
/*
        top:150px;
        /*
        visibility: hidden;
        opacity: 0;
        */
        z-index: 200;
        transform: translateX(-100%); 
        border-radius: 10px;
        padding-top: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;
        transition: all 0.3s ;
    } 
    
    .sidedrawer.show {
        /*
        visibility: visible;
        opacity: 1;*/
        transform: translateX(0);
    }
    
    
    .sidedrawer__all {
        display: flex;
        flex-direction: column;
        list-style: none;
        /*height: 669px;*/
    }
    
    
}


.event__all {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    border-radius: 10px;
    background: rgba(237, 237, 237, 0.25);
    
}

/*.event__all:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}*/

.event__public {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgb(30, 92, 208);
    color: #ffffff;
    cursor: pointer;  
    padding: 0 0.5rem;
    border-radius: 7px;
    margin-bottom: 5px;
    
  }
 
  .event__location {
    margin-left: 5px;
    margin-bottom: 3px;
    margin-top: 2px;
    cursor: pointer;
    font-weight: 500;
    width: 225px;
    overflow:hidden;
    font-size:15px;
}
.event__location__badge {
    margin-top: 3px;
    position: absolute;
    right:20px;
    top:inherit;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:black;
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}
.event__location__badge:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}

.event__location:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}

.event__top {
    display: flex;
    justify-content: space-between;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}


.event__title {
    display: flex;
    z-index: 9;
    flex: 0.85 1;
}

.event__title .icon {
    
    font-size: 150%;
    color: #000000;
}
.event__type {
    width: 50px;
    margin-top: 3px;
    margin-right: 5px;
    font-size: 80%;
    padding: 1px 4px;
    background: gold;
    color: black;
    cursor: pointer;  
    border-radius: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    box-shadow: 2px 2px rgba(0,0,0,0.4);
    
}

.event__addevent .icon {
    cursor: pointer;
    position: absolute;
    right:47px;
    top:inherit;
    margin-top: 4px;
    margin-left: 3px;
    font-size: 14px;
    padding: 1px 3px;
    background: gold;
    color: black;
    border-radius: 10px;
    text-align: center;
    box-shadow: 1.5px 1.5px rgba(0,0,0,0.4);




}
.event__addevent .icon:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

  

.event__with {
    display: flex;
    
    max-width: 100%;
    padding-bottom: 8px;
    border-radius: 15px;
}
.event__pic {
    margin-left: 5px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    width: 100%;

}

.event__info {
    width: 96%;
    padding: 5px 5px;
    margin-top: 3px;
    margin-left: 6px;
    background: white;
    font-family: 'Lato', sans-serif;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    
}
.event__time {
    margin-left: 2px;
    margin-bottom: auto;
    margin-top: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 700;
}
.event__detail {
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    width: 158px;
    font-size: 14px;
    overflow: hidden;
}
.event__withamount {
    font-weight: 700;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.event__withamount .icon{
    margin-bottom: 2px;
}


  .event__loading {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #000000; /* Blue */
    border-bottom: 3px solid #fcca00; /* Blue */
    border-radius: 50%;
    margin: auto;
    width: 10px;
    height: 10px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (max-width: 960px) { 
    .event__detail {
        margin-left: 5px;
        width: 145px;
        font-size: 13px;
        overflow: hidden;
        margin-top: auto;
        margin-bottom: auto;
    }
    .event__withamount {
        font-weight: 700;
        margin: auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .event__time {
        margin-left: 2px;
        margin-bottom: auto;
        margin-top: auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 12px;
        font-weight: 700;
    }
  }
.withuser__pic {
    
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    position:relative;
    padding-top:5px;
    display:inline-block;
}
.with__pic__accept {
    margin-left: 3px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 20px;
    /*border: 0.5px solid rgb(34, 169, 0);*/
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
    cursor: pointer;  
}

.withuser__pic:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

.with__pic__accept__badge {
    position: absolute;
    right:0px;
    bottom:-5px;
    background:rgb(0, 135, 16);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
}

.with__pic__reject {
    -webkit-filter:contrast(50%);
            filter:contrast(50%);
    margin-left: 3px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 20px;
    /*border: 1px solid rgb(175, 57, 2);
    box-shadow: 0 0 3px 4px rgb(118, 118, 118);*/
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
    cursor: pointer;  
}

.with__pic__reject__badge {
    position: absolute;
    right:0px;
    bottom:-5px;
    background:rgb(175, 57, 2);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
}




.with__pic__request {
    -webkit-filter: contrast(100%);
            filter: contrast(100%);
    margin-left: 3px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 20px;
    /*border: 3px solid rgb(0, 0, 0);
    box-shadow: 0 0 3px 4px rgb(118, 118, 118);*/
    box-shadow: 4px 2px 3px rgb(2, 2, 2);
    cursor: pointer;  
}

.with__pic__wait {
    position: absolute;
    right:0px;
    bottom:-5px;
    background:rgb(0, 0, 0);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
}
.eventsettingdrawer { 
    visibility: hidden;
    opacity: 0;
} 

.eventsettingdrawer.show {
    visibility: visible;
    opacity: 1;
}

.eventsetting__main {
    color: white;
    margin-top: 2px;
    background:black;
    display: flex;
    flex-direction: column;
    font-size: 90%;
    padding: 4px 6px;
    width: 150px;
    position: absolute;
    right:-140px;
    top:inherit;
    z-index: 10;
}
.eventsetting__who {
    text-align: center;
    font-size: 100%;
    color: rgb(206, 206, 206);
}

.eventsetting__invite {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.eventsetting__invite__add .icon {
    padding: 1px 1px;
    background: gold;
    color: black;
    border-radius: 50%;
    font-size: 120%;
    font-weight: 700;
    margin-bottom: 2px;
}
.eventsetting__invite__add .icon:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}

.eventsetting__public {
    margin: 3px 8px;
    text-align: center;
}

.eventsetting__public__select {
    background: gold;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__public:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}
.eventsetting__friend {
    margin: 3px 5px;
    text-align: center;
}
.eventsetting__friend__select {
    background: gold;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__friend:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}
.eventsetting__me {
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__me__select {
    background: gold;
    color: black;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__me:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}

.eventsetting__edit {
    background: rgb(30, 92, 208);
    color: white;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__edit:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}
.eventsetting__delete {
    background: rgb(201, 20, 0);
    color: white;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__map {
    background: rgb(46, 46, 46);
    color: gold;
    border-radius: 5px;
    padding: 2px 4px;
    margin: 3px 3px;
    text-align: center;
}
.eventsetting__delete:hover, .eventsetting__map:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
}

@media (max-width: 960px) { 
    .eventsetting__main {
        color: white;
        margin-top: 2px;
        background:black;
        display: flex;
        flex-direction: column;
        font-size: 90%;
        padding: 4px 6px;
        width: 150px;
        position: absolute;
        right:70px;
        top:inherit;
        z-index: 50;
    }
}
.logindrawer { 
    width: 260px; 
    height: -webkit-fit-content; 
    height: -moz-fit-content; 
    height: fit-content;
    background: rgb(247, 247, 247);
    position: fixed;
    top:50px;
    right:0;
    z-index: 200;
    transform: translateX(+100%); 
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    border-radius: 10px;
} 

.logindrawer.show {
    transform: translateX(0);
}


.logindrawer__links {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.logindrawer__links > li {
    display: flex;
    align-items: center;  
}

.logindrawer__links > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1 1;
    padding: 1rem;
    text-decoration: none;
    color: #171717;
    font-size: 1.0rem;
}

.logindrawer__links > li > a:hover {
    background: #171717;
    color: #f4f4f4
}

.logindrawer__title {
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    font-size: 120%;
}

.user__main {
    display: flex;
    flex-direction: column;
}

.user__area1 {
    flex: 0.4 1;
}

.user__pic {
    display: block;
    margin: 10px 75px;
    object-fit:cover;
    width: 100px;
    height: 100px;
    overflow:hidden;
    border-radius: 50px;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
  }



.user__name {
    text-align: center;
    font-size: 130%;
}
.user__email {
    text-align: center;
}
.user__link {
    text-align: center;
}
.user__pkey {
    margin-top: 5px;
    margin-bottom: 5px;
    background: black;
    color: gold;
    text-align: center;
}
.user__pkey__buy {
    margin-left: 10px;
    
    background:grey;
    text-align: center;
    border-radius: 10px;
    color:white;
    padding: 0px 5px;
    font-size: 85%;
    cursor:not-allowed;
}
.user__btn {
    /*justify-items: center;*/
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    
}
.user__btn2 {
    /*justify-items: center;*/
    text-align: center;
    margin-top: 5px;
}
.feedback__send__btn{
    padding: 5px 10px;
    background:rgb(0, 97, 194);
    color: white;
    border-radius: 5px;
    letter-spacing: 1px;  
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    cursor: pointer;
    
}

.user__btn:hover, .user__setting:hover,.feedback__send__btn:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

.user__setting {
    position: absolute;
    right:5px;
    top:-10px;
    background:transparent;
    text-align: center;
    color:rgb(106, 106, 106);
    padding: 5px 5px;
    font-size:30px;
    z-index:10;
    cursor: pointer;
}

.user__feedback__text {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}



.eventscreate { 
    
    background: rgb(255, 255, 255);
    position:absolute;
    top:21%;
    left:29%;
    z-index: 200;
    visibility: hidden;
    opacity: 0;
    /*transform: translateY(-500%); */
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    border-radius: 10px;
} 

.eventscreate.show {
    visibility: visible;
    opacity: 1;
    /*transform: translateY(0);*/
}

.create__title {
    height: 40px;
    font-size: 18px;
    padding: 5px 5px;
    background: black;
    color: white;
    text-align: center;
}

.create__date {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 5px 5px;
}
.create__date .icon{
    margin-top: 5px;
}

.create__date .icon:hover{
    transform: scale(1.25);
    transition: .25s ease-out;
    color: #0e0f10;
    cursor: pointer;
}

.create__optionicon {
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(9,1fr);
    width: 100%;
}

.create__icon {
    margin-top: 5px;
    background: rgb(230, 230, 230);
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 20px;
    cursor: pointer;  
    font-size: 160%;
    text-align: center;
}

.create__selectedicon {
    margin-top: 5px;
    background: rgb(230, 230, 230);
    box-shadow:0 0 2px 2px rgb(72, 72, 72);
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 20px;
    cursor: pointer;  
    font-size: 160%;
    text-align: center;
}

.create__optioncolor {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(10,1fr);
    width: 100%;
}

.create__color {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;  
}

.create__selectcolor {
    /*box-shadow: 4px 4px rgba(0,0,0,0.4);*/
    /*border: 2px solid rgb(63, 63, 63);*/
    box-shadow:0 0 3px 3px rgb(72, 72, 72);
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;  
}
.create__alllocation {
    margin: 5px 5px;
    display: flex;
    flex-direction: row;
    
}
.create__leftlocation {
    flex: 0.9 1;
    
}
.create__rightlocation {
    margin-left: 5px;
    margin-top: 2px;
    flex: 0.1 1;
    
}
.create__checklocation {
    height: 50px;
    font-size: 80%;
}




.create__detail {
    margin: 10px 5px;
}

.create__time {
    display:flex;
    width: 100%;
    justify-content: space-between;
    
}

.create__starthour {
    margin-left: 5px;
    flex: 0.25 1;
}

.create__startminute {
    flex: 0.25 1;
    margin-right: 30px;
}
.create__endhour {
    margin-left: 5px;
    flex: 0.25 1;
}

.create__endminute {
    flex: 0.25 1;
    margin-right: 30px;
}

.create__timetitle {
    display:flex;
    width: 100%;
    justify-content: space-between;
    
    margin-bottom: 5px;
}

.title__starttime {
    margin-left: 10px;
    
}
.title__endtime {
    margin-left: 170px;
   
}
.title__duration__OK {
    margin-right: 10px;
    color: green;
}
.title__duration__NG {
    margin-right: 10px;
    color: red;
}
.create__createbtn{
    margin: 10px 10px;
    text-align: center;
    justify-items: center;
}
.aaaaa {
    width: 100%;
}

.create__invite {
    position: absolute;
    top: -2px;
    right: 10px;
    font-size: 160%;
    color: rgb(255, 255, 255);
    text-align: center;
}
.create__invite__amount {
    position: absolute;
    top: 12px;
    right: 40px;
    font-size: 70%;
    background: gold;
    color: black;
    text-align: center;
    padding: 1px 5px;
    border-radius: 10px;
    font-weight: 700;
}

.create__invite > span {
    cursor: pointer;
}

.create__main {
    width: 500px;
    height: 550px;
}

.create__maininvite {
    width: 800px;
    display: flex;
    height: 550px;
}

.create__leftshow {
    flex: 0.62 1;
    height: 550px;
    border-radius: 10px;
}

.create__lefthide {
    width: 500px;
    height: 550px;
}

.create__centershow{
    flex: 0.07 1;
    display: flex;
    flex-direction: column;
    background:rgb(148, 148, 148);
    height: 550px;
    transition: 0.25s all;
}

.create__centerhide{
    width: 0px;
    height: 550px;
}

.create__with {
    margin-top: 10px;
    margin-left: 8px;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: rgb(223, 223, 223);  
    justify-content: center;
    text-align: center;
    font-size: 160%;
    color: white;
    font-weight: 700;
}

.create__rightshow{
    flex: 0.31 1;
    display: flex;
    flex-direction: column;
    background:rgb(228, 228, 228);
    height: 550px;
    transition: 0.25s all;
    border-radius: 10px;
}

.create__righthide{
    width: 0px;
    height: 550px;
}

.create__friendlist {
    overflow-y: scroll;
    max-height: 500px;
}
.create__friendlist__not {
    margin-top: 10px;
    font-size: 100%;
    text-align: center;
    font-family: 'Lato', sans-serif;
    color: rgb(0, 0, 0);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.friend__search {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 80%;
    background-color: white;
    border-radius: 5px;
    padding: 0.5px 15px;   
    
}

@media (max-width: 960px) { 
    .eventscreate { 
        width: 100%; 
        height: 520px;
        background: transparent;
        position:fixed;
        top:0;
        left:0;
        z-index: 200;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
    }  
    .eventscreate.show {
        visibility: visible;
        opacity: 1;
    }
    .create__title {
        height: 40px;
        font-size: 18px;
        padding: 5px 5px;
        background: black;
        color: white;
        text-align: center;
    }
    .create__main {
        width: 100%;
        height: 100%;
    }
    
    .create__lefthide {
        width: 100%;
        height: 100%;
        background: white;
    }
    .create__optioncolor {
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(10,1fr);
        width: 100%;
    }
    
    .create__color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;  
    }
    .create__selectcolor {
        box-shadow:0 0 3px 3px rgb(72, 72, 72);
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;  
    }
    .create__optionicon {
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(9,1fr);
        width: 100%;
    }
    
    .create__icon {
        margin-top: 5px;
        background: rgb(230, 230, 230);
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;  
        font-size: 130%;
        text-align: center;
    }
    
    .create__selectedicon {
        margin-top: 5px;
        background: rgb(230, 230, 230);
        box-shadow:0 0 2px 2px rgb(72, 72, 72);
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;  
        font-size: 130%;
        text-align: center;
    }
    .create__timetitle {
        display:flex;
        width: 100%;
        justify-content: space-between;
        
        margin-bottom: 5px;
    }
    .title__duration__OK {
        margin-top: 5px;
        margin-right: 20px;
        color: green;
        font-size: 60%;
    }
    .title__duration__NG {
        margin-top: 5px;
        margin-right: 20px;
        color: red;
        font-size: 60%;
    }
    .title__endtime {
        margin-left: 120px;
    }
    .create__maininvite {
        width: 100%;
        display: flex;
        
        height: 100%;
    }
    .create__maininvite > .create__leftshow {
        flex: 0 1;
        display: none;
    }
    .create__maininvite > .create__centershow{
        flex: 0.15 1;
        display: flex;
        flex-direction: column;
        background:rgb(148, 148, 148);
        height: 100%;
        transition: 0.25s all;
        overflow-y: scroll;
    
    }
    .create__maininvite > .create__rightshow{
        flex: 0.85 1;
        display: flex;
        flex-direction: column;
        background:rgb(228, 228, 228);
        height: 100%;
        transition: 0.25s all;
        border-radius: 10px;    
    }
}
.createwith__detail {
    display: flex;
    margin: 5px 5px;
    background: rgb(255, 255, 255);
    
    border-radius: 5px;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
    cursor: pointer; 
}

.createwith__detail:hover {
    transform: scale(1.03);
    transition: .25s ease-out;
    background: rgb(0, 0, 0);
    color: white;
}
.createwith__picarea {
    flex: 0.25 1;
}

.createwith__pic {
    
    margin: 5px 5px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
}



.createwith__name {
    flex: 0.75 1;
    margin-top: 12px;
    margin-left: 5px;
    
}
@media (max-width: 960px) { 
    .createwith__detail {
        display: flex;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        width: 80%;
        height: 60px;
        border-radius: 5px;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
        cursor: pointer; 
    }
    .createwith__picarea {
        flex: 0.25 1;
    }
    .createwith__pic {
        
        margin: 5px 5px;
        display: block;
        width: 50px;
        height: 50px;
        object-fit: cover;
        overflow:hidden;
        border-radius: 50%;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
    }
    .createwith__name {
        flex: 0.75 1;
        margin-top: 15px;
        margin-left: 15px;
        
    }
}

.invite__pic {
    margin-top: 10px;
    margin-left: 8px;
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%; 
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
}

.inviteuser__pic {
    position:relative;
    /*padding-top:20px;*/
    display:inline-block;
}

.invite__cancle{
    position: absolute;
    right:3px;
    top:5px;
    background:red;
    text-align: center;
    border-radius: 20px;
    color:white;
    padding: 1px 4.5px;
    font-size:9px;
    font-weight: 1000;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgb(0, 0, 0);
}

.invite__cancle:hover {
    transform: scale(1.30);
    transition: .25s ease-out;
}
.notification { 
    width: 300px; 
    height: 90%;
    background: rgb(36, 36, 36);
    position: fixed;
    top: 50px;;
    right:5px;
    z-index: 200;
    transform: translateX(+105%); 
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    color: white;
    /*justify-content: center;*/
} 

.notification.show {
    transform: translateX(0);
}

.notification__title {
    font-size: 150%;
    
    margin: 5px 10px;
}


.item__detail {
    display: flex;
    margin: 5px 10px;
    background:transparent;
    border-radius: 10px;
    border: 1px solid rgb(63, 63, 63);
    box-shadow: 3px 3px rgba(0,0,0,0.4);
    
}

.item__detail:hover {
    transform: scale(1.03);
    transition: .25s ease-out;
    background: rgb(56, 56, 56);
}

.item__left {
    flex: 0.2 1;
}

.item__pic {
    margin: 5px 5px;
    display: block;
    height: 50px;
    width: 50px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgb(63, 63, 63);
}

.item__right {
    margin-top: 4px;
    margin-left: 5px;
    flex: 0.8 1;
    display: flex;
    flex-direction: column;
}

.item__name {
    font-weight: 600;
    font-family: 'Lato', sans-serif;
}

.item__info {
    font-family: 'Lato', sans-serif;
    font-size: 85%;
}
.item__info__go {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    padding: 5px 10px;
    background: green;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.item__info__busy {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    padding: 5px 10px;
    background: red;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.item__info2 {
    font-family: 'Lato', sans-serif;
}

.item__title {
    margin-top: 3px;
    cursor: pointer;
}
.item__title .icon{
    margin-bottom: 2px;
    font-size: 120%;
}


.item__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.item__btnevent {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.item__btnevent > .item__go {
    margin-bottom: 5px;
}
.item__btnevent > .item__busy {
    
    margin-bottom: 5px;
}
.item__go:hover, .item__busy:hover {
    transform: scale(1.05);
    transition: .25s ease-out;
}


.item__result {
    background: rgb(116, 116, 116);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
}

.item__accept:hover, .item__decline:hover {
    transform: scale(1.1);
    transition: .25s ease-out;
}

.item__location__badge {
    margin-top: 3px;
    position: absolute;
    right:20px;
    top:inherit;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:black;
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}
.item__location__badge:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}

.register { 
    
    background: rgb(255, 255, 255);
    position:fixed;
    top:11%;
    left:38%;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    /*transform: translateY(-500%); */
    transition: all 0.3s ;
    border-radius: 10px;
    /*justify-content: center;*/
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
} 

.register.show {
    visibility: visible;
    opacity: 1;
    /*transform: translateY(0);*/
}

.register__title {
    height: 40px;
    font-size: 18px;
    padding: 5px 5px;
    background: rgb(191, 191, 191);
    color: rgb(0, 0, 0);
    text-align: center;
}

.register__main {
    
    width: 350px;
    display: flex;
    flex-direction: column;
    height: 550px;
}
.register__main2 {
    
    width: 350px;
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    
}

.register__area1 {
    flex: 0.4 1;
    margin: 3px 3px;
    position:relative;
    padding-top:5px;
    display:inline-block;
}
.register__pic {
    display: block;
    margin: 20px 85px;
    object-fit:cover;
    width: 180px;
    height: 180px;
    overflow:hidden;
    border-radius: 125px;
    box-shadow: 4px 2px 3px rgb(0, 0, 0);
  }
  .register__pic__badge {
    position: absolute;
    right:25px;
    bottom:15px;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:rgb(0, 0, 0);
    padding: 1px 10px;
    font-size:15px;
    z-index:10;
    cursor: pointer;
}
.register__pic__badge:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

.register__area2 {
    flex: 0.12 1;
}
.register__name {
    margin: 5px 10px;
}

.register__area3 {
    flex: 0.12 1;
}


.register__area4 {
    flex: 0.12 1;
}
.register__email {
    margin: 5px 10px;
}

.register__area5 {
    flex: 0.12 1;
    text-align: center;
    display: flex;
    flex-direction: row;
}
.register__title__ex {
    flex: 0.21 1;
    background: cornsilk;
    margin: 15px 5px;
}
.register__example__ok {
    flex: 0.79 1;
    padding: 5px 5px;
    background: black;
    color: gold;
    margin: 10px 5px;
    border-radius: 10px;
    font-size: 110%;
}
.register__example__ng {
    flex: 0.79 1;
    padding: 5px 5px;
    background: black;
    color: red;
    margin: 10px 5px;
    border-radius: 10px;
    font-size: 110%;
}
.register__link {
    margin: 5px 10px;
}

.register__area6 {
    flex: 0.12 1;
}
.register__btn {
    margin: 10px 10px;
}

.register__area6 > h1 {
    text-align: center;
}
.register__area6 > h2 {
    font-size: 120%;
    text-align: center;
}

@media (max-width: 960px) { 
    .register { 
        width: 100%; 
        height: 520px;
        background: rgb(255, 255, 255);
        position:fixed;
        top:0;
        left:0;
        z-index: 300;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ;
        border-radius: 10px;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
        justify-content: center;
    } 
    .register.show {
        visibility: visible;
        opacity: 1;
    }
    .register__main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
    }
    .register__area1 {
        flex: 0.2 1;
        margin: 3px 3px;
        padding-top:5px;
        display:inline-block;
    }
    .register__pic {
        display: block;
        object-fit:cover;
        width: 125px;
        height: 125px;
        overflow:hidden;
        border-radius: 50%;
        box-shadow: 4px 2px 3px rgb(0, 0, 0);
        margin-left: auto;
        margin-right: auto;
      }
      .register__pic__badge {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        background:gold;
        right:0;
        top:15px;
        border-radius: 20px;
        color:rgb(0, 0, 0);
        padding: 1px 10px;
        font-size:15px;
        z-index:10;
        cursor: pointer;
    }
    .register__pic__badge:hover {
        transform: scale(1.10);
        transition: .25s ease-out;
    }
    .register__btn {
        margin: 2.5px 10px;
    }
    .register__link {
        margin: 2.5px 10px;
    }
    
    .register__main2 {
    
        width: 100%;
        display: flex;
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        
    }

}
.searchdrawer { 
    width: 300px; 
    height: 90%;
    background: rgb(38, 38, 38);
    position: fixed;
    top: 50px;
    right: 0%;
    z-index: 100;
    transform: translateX(+100%); 
    transition: all 0.3s ;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    border-radius: 10px;
    overflow-y: scroll;
    
} 

.searchdrawer.show {
    transform: translateX(0);
}

.searchdrawer__not {
    text-align: center;
    font-size: 100%;
    color: white;
}

.searchdrawer__title {
    
    height: 34px;
    
    font-size: 17px;
    padding: 3px 3px;
    background: gold;
    color: rgb(0, 0, 0);
    text-align: center;
    
}
@media (max-width: 960px) { 
    .searchdrawer { 
        width: 250px; 
        height: 90%;
        background: rgb(38, 38, 38);
        position: fixed;
        top: 50px;
        right: 0%;
        z-index: 500;
        transform: translateX(+100%); 
        transition: all 0.3s ;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        overflow-y: scroll;
    } 
}
.accountsearch__detail {
    display: flex;
    margin: 5px 5px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(63, 63, 63);
    box-shadow: 3px 3px rgba(0,0,0,0.4);
    cursor: pointer; 
}

.accountsearch__detail:hover {
    transform: scale(1.03);
    transition: .25s ease-out;
    background: rgb(0, 0, 0);
    color: white;
}

.accountsearch__left {
    flex: 0.18 1;
    
}
.accountsearch__pic {
    margin: 5px 5px;
    display: block;
    height: 45px;
    width: 45px;
    object-fit: cover;
    overflow:hidden;
    border-radius: 50%;
    border: 1px solid rgb(63, 63, 63);
}



.accountsearch__info {
    flex: 0.82 1;
    margin-top: 3px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}







.maindrawer { 
    width: 100%; 
    height: 100vh;
    background: rgb(196, 189, 189);
    position: fixed;
    top:48px;
    right:0;
    z-index: 50;
    visibility: hidden;
    opacity: 0;
    /*transform: translateY(-500%); */
    transition: all 9s ;
    
    /*justify-content: center;*/
    
    
    
} 

.maindrawer.show {
    visibility: visible;
    opacity: 1;
}

.mainscreen__main {
    
    display: flex;
    flex-direction: column;
    
}
.mainscreen__top {
    flex: 0.9 1;
    display: flex;
    flex-direction: row;
    
}
.mainscreen__foot {
    flex: 0.1 1;
    display: flex;
    justify-content: space-between;
    
    margin-left: 100px;
    margin-right: 100px;
    font-size: 150%;
}


.mainscreen__left {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    flex: 0.75 1;
    
}

.mainscreen__right {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 0.25 1;
    background-color: rgb(14, 14, 14);
    
}

.mainscreen__pic {
    
    width: 100%;
    height: 100%;
    /*border-radius: 20px; */
}



.mainscreen__message {
    font-size: 320%;
    font-weight: 500;
    position: absolute; 
    top: 72%; 
    left: 40px; 
    color: white;
    letter-spacing: 4px;  
   background: rgb(0, 0, 0); /* fallback color */
   background: rgba(46, 46, 46, 0.7);
   padding: 7px; 
   
}
.mainscreen__message__dream {
    font-size: 320%;
    font-weight: 500;
    position: absolute; 
    top: 51%; 
    left: 811px; 
    color: gold;
    letter-spacing: 4px;  
    
    /*
   background: rgb(0, 0, 0); /* fallback color 
   background: rgba(46, 46, 46, 0.7);
   padding: 5px;  */
}
.mainscreen__message__love {
    font-size: 300%;
    font-weight: 500;
    position: absolute; 
    top: 58%; 
    left: 858px; 
    color: rgb(255, 255, 255);
    letter-spacing: 4px;  
    
    /*
   background: rgb(0, 0, 0); /* fallback color 
   background: rgba(46, 46, 46, 0.7);
   padding: 5px;  */
}
.mainscreen__message__like {
    font-size: 220%;
    font-weight: 500;
    position: absolute; 
    top: 65.5%; 
    left: 811px; 
    color: rgb(255, 255, 255);
    letter-spacing: 4px;  
    
    /*
   background: rgb(0, 0, 0); /* fallback color 
   background: rgba(46, 46, 46, 0.7);
   padding: 5px;  */
}

.mainscreen__box {
    width: 25%;
    height: 86%;
    position: absolute; 
    top: 4%; 
    right: 80px; 
    letter-spacing: 4px;  
   /*background: rgb(0, 0, 0); /* fallback color */
   background: rgb(0, 0, 0,0.75); 
   padding: 5px;  
   border-radius: 20px;
   display: flex;
   flex-direction: column;
   font-family: 'Lato', sans-serif;

   
}

.mainscreen__area1 {
    flex: 0.65 1;
    margin: 3px 3px;
    position:relative;
    padding-top:5px;
    display:inline-block;
}
.mainscreen__logo {
    display: block;
    margin: 1% 25%;
    /*object-fit:cover;*/
    width: 50%;
    height: 50%;
    overflow:hidden;
    
    
  }
  .mainscreen__area1 > h1 {
    font-size: 200%;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 3px;  
    text-align: center;
    margin-bottom: 5%;
}
.mainscreen__area1 > h2 {
    font-size: 150%;
    font-weight: 700;
    margin-left: 10%;
    
    margin-bottom: 2%;
    color: gold
}


.mainscreen__area1 > h3 {
    margin-left: 12%;
    font-size: 130%;
    margin-bottom: 2%;
    color: rgb(255, 255, 255);
}
.mainscreen__area1 > h4 {
    font-size: 130%;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 3px;  
    text-align: center;
    
}

.mainscreen__area2 {
    flex: 0.35 1;
}
.mainscreen__area2 > h2 {
    font-size: 180%;
    font-weight: 700;
    margin-left: 10%;
    margin-top: 12%;
    margin-bottom: 3%;
    color: gold
}

.mainscreen__area2 > h3 {
    margin-left: 15%;
    letter-spacing: 2px;  
    font-size: 120%;
    margin-bottom: 3%;
    color: rgb(255, 255, 255);
}




@media (max-width: 960px) { 
    .maindrawer { 
        width: 100%; 
        height: 100%;
        background: rgb(0, 0, 0);
        position: fixed;
        top:48px;
        left:0;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s ;
        justify-content: center;
        

    }  
    .maindrawer.show {
        visibility: visible;
        opacity: 1;
    }
    .mainscreen__pic {
        display: none;
    }
    .mainscreen__message, .mainscreen__message__dream, .mainscreen__message__like ,.mainscreen__message__love,.mainscreen__area2, .mainscreen__logo {
        display: none;
    } 
    .mainscreen__box {
        width: 100%;
        height: 100%;
        position: absolute; 
        top: 4%; 
        left: 0%; 
        letter-spacing: 4px;  
       
       background: rgb(0, 0, 0,0.75); 
       
       border-radius: 20px;
       display: flex;
       flex-direction: column;
       font-family: 'Lato', sans-serif;
    
       
    }
    .mainscreen__login {
        width: 100%;
        margin-top: 10px;
        
        margin-bottom: 10px;
        justify-content: center;
        display: flex;
        
    }
    .mainscreen_login_btn {
        text-align: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 40px;
        justify-content: center;
        
        
    }
    .mainscreen__area1 {
        width: 100%;
        margin: 3px 3px;
        padding-top:5px;
    }
   
    .mainscreen__area1 > h1 {
        font-size: 180%;
        font-weight: 700;
        color: rgb(255, 255, 255);
        letter-spacing: 3px;  
        text-align: center;
        margin-bottom: 5%;
    }
    .mainscreen__area1 > h2 {
        font-size: 120%;
        font-weight: 700;
        margin-left: 0;
        text-align: center;
        margin-bottom: 2%;
        color: gold
    }
    
    
    .mainscreen__area1 > h3 {
        text-align: center;
        font-size: 100%;
        margin-left: 0;
        margin-bottom: 2%;
        color: rgb(255, 255, 255);
    }
    .mainscreen__area1 > h4 {
        font-size: 130%;
        font-weight: 700;
        color: rgb(255, 255, 255);
        letter-spacing: 3px;  
        text-align: center;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .mainscreen__area1 > h6 {
        margin-top: 5px;
        font-size: 110%;
        font-weight: 700;
        
        color: rgb(255, 255, 255);
        
        text-align: center;
    }
    .mainscreen__area1 > h5 {
        margin-top: 5px;
        font-size: 140%;
        font-weight: 700;
        color: rgb(96, 181, 0);
        letter-spacing: 3px;  
        text-align: center;
    }
    .mainscreen__mobile {
        width: 100%;
        margin-top: 20px;
        
       
        justify-content: center;
        display: flex;
        
    }
    .mainscreen__appstore {
        /*object-fit:cover;*/
        width: 150px;
        height: 45px;
        
    }
    .mainscreen__googlestore {
        /*object-fit:cover;*/
        width: 150px;
        height: 45px;
        
    }
    
    
        
}
.homescreen__loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #000000; /* Blue */
    border-bottom: 16px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 110px;
    height: 110px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 30%;
    left: 45%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .homescreen__loadtext {
    font-size: 150%;
    position: absolute;
    top: 20%;
    left: 46%;
  }
  @media (max-width: 960px) { 
    .homescreen {
      width: 100%;
      height: 100%;
    }
    .homescreen__loading {
      border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    border-bottom: 5px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 30%;
    left: 47%;
    z-index: 500;
    margin-top: 30px;
    }
  
    .homescreen__loadtext {
      display: none;
    }
  }
.userscreen {
    display:flex;

    max-width:1450px;
    /*align-items: center;*/
    justify-content: center;
    margin: 1rem auto;
}



.userscreen__primary {
    flex: 0.75 1;
    background: rgb(19, 18, 18);
    box-shadow: 0 4px 4px rgba(0,0,0,0.4);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 5px;
    margin-left: 1rem;
    margin-right: 1rem;
    
    /*padding-bottom: 15px*/;
}

.userscreen__secondary {
    flex: 0.25 1;
    background: rgb(232, 232, 232);
    box-shadow: 0 1px 4px rgba(0,0,0,0.4);
    border-radius: 5px;
    margin-right: 1rem;
    flex-direction: column;
    height: 669px;
}

.userscreen__primary2 {
  flex: 0.75 1;
  background: rgb(19, 18, 18);
  box-shadow: 0 4px 4px rgba(0,0,0,0.4);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  
  /*padding-bottom: 15px*/;
}

.userscreen__secondary2 {
  flex: 0.25 1;
  background: rgb(232, 232, 232);
  box-shadow: 0 1px 4px rgba(0,0,0,0.4);
  border-radius: 5px;
  margin-right: 1rem;
  flex-direction: column;
  height: 669px;
}

.userscreen__secondary__hide {
  flex:0 1;
  opacity: 0;
}

/* ****************** SCHEDULE COMPONENT STYLE  ************************ */

.icon, .icon2, .icon__friend,.icon__favourite,.icon2__favourite {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* GENERAL */

.schedule__header { /*reactcalendar เดี๋ยวจะเอามาทำหน้าแสดง Profile*/
    display: block;
    width: 100%;
    padding: 0.5em 0;
    /*border-bottom: 1px solid #eee;*/
    background: rgb(0, 0, 0);
}
.schedule__header #logo {
    font-size: 175%;
    text-align: center;
    color: #ffffff;
    line-height: 1;
}

.schedule__header #logo .icon {
    padding-right: .25em;
}

.schedule__body { 
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: #777;
    background: #000000;
    position: relative;
    
    box-shadow: -10px 10px 10px 0px #000000;

}

.schedule__body > main { /*Zone  ปฏิทิน*/
    
    display: block;
    margin: 0 auto;
  }

/* Zone Profile ด้านบนปฏิทิน*/


.header__account {
    width: 100%;
    height: 75px; 
    background-color: #000000 ;
    display: flex; 
    /*justify-content: space-between;*/
}

.account__left {
    margin-left: 10px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
  }

.account__pic {
    display: block;
    margin-top: 3px;
    object-fit:cover;
    width: 70px;
    height: 70px;
    overflow:hidden;
    cursor: pointer;  
    border-radius:50%;
    box-shadow: 4px 2px 7px gold;
  }

  .account__center {
    flex: 0.8 1;
    display: flex; 
    justify-content: space-between;
    margin-right: 20px;
  }
  .account__nameplate {
    display: flex;
    flex: 0.45 1;
    flex-direction: column;
    text-align: left;
  }
  .account__stat {
    flex: 0.55 1;
    justify-content: space-between;
    display: flex; 
  }

  .account__name {
    font-size: 30px;
    color: #fff;
  }
  .account__id {
    font-size: 18px;
    color: rgb(228, 232, 8);
  }

  .account__id__badge {
    margin-left: 2px;
    margin-bottom: 5px;
    background:rgb(0, 95, 184);
    text-align: center;
    border-radius: 20px;
    color:rgb(255, 255, 255);
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}
.account__id__badge:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}



  .account__follower {
    margin-left: 50px;
  }

  .account__follower > h2 {
    font-size: 30px;
    color: #fff;
    text-align: right;
    cursor: pointer;
    
  }
  .account__follower > p {
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  .account__following {
    margin-left: 50px;
  }

  .account__following > h2 {
    font-size: 30px;
    color: #fff;
    text-align: right;
    cursor: pointer;
    
  }
  .account__following > p {
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  .account__event {
    margin-left: 50px;
  }

  .account__event > h2 {
    font-size: 30px;
    color: #fff;
    text-align: right;
    
  }
  .account__event > p {
    font-size: 18px;
    color: #fff;
    text-align: right;
  }

  .account__right {
    flex: 0.2 1;
    display: flex;
    flex-direction: column;
  }

  .account__righttop{
    flex: 0.5 1;
  }

  .account__public {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position:absolute;
    right: 1px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgb(30, 92, 208);
    color: #ffffff;
    display: flex;
    margin-right: 10px;
    padding: 0 0.5rem;
    border-radius: 7px;
    margin-bottom: 10px;
    font-size: 110%;
    font-weight: 500;
  }

  .account__public > span{
    margin-left: 5px;
    margin-top: 8px;
    font-size: 70%;
    
  }

  .account__followbtn {
    cursor: pointer;
  }

  .account__public > span:hover{
    transform: scale(1.25);
    transition: .25s ease-out;
    cursor: pointer;  
  }
  

  

  .account__rightbottom{
    flex: 0.5 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .account__relation {
    margin-top: 11px;
    margin-right: 11px;
    background: rgb(0, 43, 9);
    border-radius: 10px;
    padding: 3px 5px;
  }
  .account__relation > span {
   
    margin-left: 5px;
    font-size: 200%;
    color: white;
    /*cursor: pointer;*/
  }

  .account__toggle {
    color:gold;
    font-size: 80%;
  }


.account__relation > .icon {
    cursor:pointer;
    color: #ffffff;
}
.account__relation > .icon2 {
  cursor: pointer;
  color: gold;
}
.account__relation > .icon__friend {
  cursor:default;
  color: gold;
}
.account__relation > .icon__favourite {
  cursor:pointer;
  color: #ffffff;
}
.account__relation > .icon2__favourite {
cursor: pointer;
color: gold;
}
.account__relation > .icon:hover,  .account__relation > .icon2:hover,.account__relation > .icon__favourite:hover,.account__relation > .icon2__favourite:hover {
  transform: scale(1.20);
  transition: .25s ease-out;
}

.eventactive__title {
  font-size: 120%;
  text-align: center;
  background: rgb(0, 0, 0);
  color: #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-between;
  display: flex;
}
.eventactive__today {
  margin-left: 20%;
  
}
.eventactive__hotevent2 {
  cursor: pointer;
  margin-top: 2px;
  margin-right: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: gold;
  color: black;
  display: flex;
  font-size: 13px;
  text-align: center;
  padding: 0px 3px;
  border-radius: 5px;
  font-weight: 500;
}
.eventactive__hotevent2 > span {
  margin-top: 4px;
  margin-right: 1px;
  background: gold;
  color: rgb(237, 0, 0);
  font-size: 13px;
}
.eventactive__hotevent {
  cursor: pointer;
  margin-top: 2px;
  margin-right: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: rgb(100, 100, 100);
  color: rgb(255, 255, 255);
  display: flex;
  font-size: 13px;
  text-align: center;
  padding: 0px 3px;
  border-radius: 5px;
  font-weight: 500;
}
.eventactive__hotevent > span {
  margin-top: 4px;
  margin-right: 1px;
  background: rgb(100, 100, 100);
  color: rgb(255, 255, 255);
  font-size: 13px;
}
.eventactive__not {
  font-size: 100%;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 5px;
}

.eventactive__filter {
  display: flex;
  justify-content: space-between;
  font-size: 150%;
  background: rgb(0, 0, 0);
  color: #fff;
  box-shadow: 0 1px 4px rgba(0,0,0,0.4);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px 0px;
  margin-bottom: 1px;
}

.eventactive__filter > span {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.eventactive__filter > .icon {
  color: #ffffff;
}
.eventactive__filter > .icon2 {
  color: gold;
}

.eventactive__event {
  overflow-y: scroll;
  max-height: 555px;
}

.select__filter {
  margin-left: 8px;
  width: 80px;
}

.userscreen__loading {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #000000; /* Blue */
  border-bottom: 16px solid #fcca00; /* Blue */
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 30%;
  left: 45%;
  z-index: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.userscreen__loadtext {
  font-size: 150%;
  position: absolute;
  top: 20%;
  left: 46%;
  z-index: 500;
  /*
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;*/
}

.userscreen__loading2 {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #000000; /* Blue */
  border-bottom: 16px solid #fcca00; /* Blue */
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 36%;
  left: 46%;
  z-index: 500;
}


.userscreen__loadtext2 {
  font-size: 150%;
  position: absolute;
  top: 26%;
  left: 48%;
  z-index: 500;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

@media (max-width: 960px) { 

  .userscreen__loading {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    border-bottom: 5px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 30%;
    left: 47%;
    z-index: 500;
    margin-top: 30px;
  }
  .userscreen__loadtext {
    display: none;
  }
  .userscreen__loading2 {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #000000; /* Blue */
    border-bottom: 5px solid #fcca00; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 36%;
    left: 48%;
    z-index: 500;
    margin-top: 30px;
  }
  .userscreen__loadtext2 {
    display: none;
  }
    .userscreen 
    {
    display:flex;
    max-width:500px;
    justify-content: center;

    } 
    .userscreen__primary 
    {
      flex: 1 1;
      width: 100%; 
      background: rgb(19, 18, 18);
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      border-radius: 5px; 
      
      
    }
    .userscreen__secondary {
      flex:0 1;
      opacity: 0;
      display: none;
      
    }
  
    
    .userscreen__primary2 {
      flex:0 1;
      opacity: 0;
      display: none;
     
    }
    
    .userscreen__secondary2 {
      flex:1 1;
      width: 100%; 
      height: 600px;
      background: rgb(0, 0, 0);
      border-radius: 5px;
      flex-direction: column;
      
    }
    .userscreen__swipe__text {
      text-align: center;
      color: white;
    }
    .userscreen__swipe__text > span{
      font-size: 20px;
      color: gold;
    }
    .userscreen__swipe2__text {
      text-align: center;
      color: rgb(0, 0, 0);
    }













    .userscreen__secondary__hide {
      flex:0 1;
      opacity: 0;
      display: none;
    }
    .header__account 
    {
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      background-color: #000000 ;
      display: flex; 
      justify-content: space-between;
      
    }
  .account__left 
    {
      flex:0.2 1;
      margin-left: 10px;
      margin-right: 15px;
      justify-content: center;
      align-items: center;
    }
  .account__pic 
    {
      display: block;
      margin-top: 8px;
      object-fit:cover;
      width: 90px;
      height: 90px;
      overflow:hidden;
      cursor: pointer;  
      border-radius:50%;
      box-shadow: 4px 2px 7px gold;
    }

    .account__center {
      flex: 0.95 1;
      display: flex; 
      flex-direction: column;
      margin-right: 5px;
      
    }
    .account__stat {
      display: flex; 
      justify-content: space-between;
      text-align: right;
      width: 100%;
    }
  
    .account__name {
      font-size: 20px;
      text-align: right;
      color: #fff;
      margin-right: 25px;
    }
    .account__id {
      font-size: 13px;
      color: rgb(228, 232, 8);
    }
    .account__nameplate {
      display: flex;
      flex-direction: column;
      text-align: right;
    }

    .account__follower {
      margin-left: 20px;
      
    }
  
    .account__follower > h2 {
      font-size: 20px;
      color: #fff;
     
      cursor: pointer;
      
    }
    .account__follower > p {
      font-size: 15px;
      color: #fff;
     
    }
  
    .account__following {
      margin-left: 20px;
      
    }
  
    .account__following > h2 {
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      
    }
    .account__following > p {
      font-size: 15px;
      color: #fff;
      margin-left: 5px;
    }
  
    .account__event {
      margin-left: 5px;
      
    }
  
    .account__event > h2 {
      font-size: 20px;
      color: #fff;
      
      
    }
    .account__event > p {
      font-size: 15px;
      color: #fff;
      margin-left: 5px;
     
    }
    .account__right {
      flex:0.05 1;
    }
  
     .account__toggle {
      display: none;
    }
    
    .account__relation  {
      position: absolute;
      top: -1px;
      right: 5px;
      background: transparent;
      border-radius: 50%;
      padding: 1px 2px;
      
    }
    .account__relation > span {
      font-size: 140%;
    }
    
    .account__relation > .icon__friend {
      position:absolute;
      top: -10px;
      right: 5px;
      cursor:default;
      color: gold;
      font-size: 100%;
    }
    .account__relation > .icon2__favourite {
      position:absolute;
      top: -10px;
      right: 25px;
      cursor:default;
      color: gold;
      font-size: 100%;
    }
    .account__relation > .icon__favourite {
      position:absolute;
      top: -10px;
      right: 25px;
      cursor:default;
      color: rgb(255, 255, 255);
      font-size: 100%;
    }
    .account__public{
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      position:absolute;
      top: 10px;
      left: 80px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      background: rgb(30, 92, 208);
      color: #ffffff;
      display: flex;
      margin-right: 10px;
      padding: 0px 1px;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 70%;
      font-weight: 500;
      text-align: left;
      
    }
    .account__public > div{
      margin-left: 2px;
    }
    .account__public > span{
      margin-left: 2px;
      margin-right: 2px;
      margin-top: 4px;
      font-size: 70%;
    }
  
    .account__followbtn {
      cursor: pointer;
    }
    .eventactive__not {
      font-size: 100%;
      text-align: center;
      font-family: 'Lato', sans-serif;
      color: rgb(255, 255, 255);
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
}
/* VARIABLES */

:root {
    --main-color: #0e0f10;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }

/* SCHEDULE */

.schedule__calendar {
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    background: var(--neutral-color);
    border: 1px solid #eee;
    border: 1px solid var(--border-color);
    
  }  


.header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 2.0em;
    height: 3em;
    border-bottom: 1px solid #eee;
    border-bottom: 1px solid var(--border-color);
    align-items: center;
  }
  
.header .icon {
    cursor: pointer;
    transition: .15s ease-out;
  }
  
.header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: #0e0f10;
  }
  
.header .icon:first-of-type {
    margin-left: 1em;
  }
  
.header .icon:last-of-type {
    margin-right: 1em;
  }
  
.days {
    text-transform: uppercase;
    font-weight: 400;
    color: #ccc;
    color: var(--text-color-light);
    font-size: 90%;
    padding: .75em 0;
    border-bottom: 1px solid #eee;
    border-bottom: 1px solid var(--border-color);
  }
  
.body .cell { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
    position: relative;
    height: 5.2em;
    border-right: 1px solid #eee;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
  .body .cellh { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
    position: relative;
    height: 5.2em;
    border-right: 1px solid #eee;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: rgb(246, 246, 246);
    transition: 0.25s ease-out;
  }

  .body .hide {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-right: 1px solid #eee;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
 

  
.body .cell:hover { /* BG ตอนเอา Mouse ไปจ่อที่ปฏิทิน*/
    background:#f9f9f9;
    background:var(--bg-color);
    transition: 0.5s ease-out;
  }
  .body .cellh:hover { /* BG ตอนเอา Mouse ไปจ่อที่ปฏิทิน*/
    background:#f9f9f9;
    background:var(--bg-color);
    transition: 0.5s ease-out;
  }
  .body .hide:hover {
    background: #f9f9f9;
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }
  
.body .selected { /* style ของวันปัจจุบัน*/
    border-left: 6px solid transparent;
    border-image: linear-gradient(45deg, #29a200e0 0%,#000000 40%);
    border-image-slice: 1;
    background: rgb(0, 0, 0);
    color:white;
    font-size: 100%;
    
  }

  
  
.body .row {
    border-bottom: 1px solid #eee;
    border-bottom: 1px solid var(--border-color);
  }
  
.body .row:last-child {
    border-bottom: none;
  }
  
.body .cell:last-child {
    border-right: none;
  }
  .body .cellh:last-child {
    border-right: none;
  }
  
.body .cell .number { /* style ของเลขวันที่ในแต่ละวัน*/
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .1em;
    right: .2em;
    font-weight: 700;
    background: #fff;
    
    padding: 0px 2px;
    
  }
  .body .cellh .number { /* style ของเลขวันที่ในแต่ละวัน*/
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .1em;
    right: .2em;
    font-weight: 700;
    background:rgb(246, 246, 246);
    
    padding: 0px 2px;
    
  }
  

  .body .hide .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .1em;
    right: .2em;
    font-weight: 700;
    background:#fff;
    padding: 0px 2px;
  }
  .body .selected .number{ 
   
    padding: 0px 2px;
    background: gold;
    color:black;
    font-size: 80%;
    
    font-weight: 500;
    
  }
.body .disabled {
    color: #ccc;
    color: var(--text-color-light);
    pointer-events: none;
  }
  
.body .cell .bg { /* [Important] BG ของในแต่ละวัน*/
    font-weight: 700;
    line-height: 1;
    color:rgb(130, 130, 130);
    opacity: 0;
    font-size: 2em;
    position: absolute;
    top: 1.4em;
    right: .0em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }
  .body .cellh .bg { /* [Important] BG ของในแต่ละวัน*/
    font-weight: 700;
    line-height: 1;
    color:rgb(130, 130, 130);
    opacity: 0;
    font-size: 2em;
    position: absolute;
    top: 1.4em;
    right: .0em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }

  .body .selected:hover { /* [Important] BG ของในแต่ละวัน*/
    color:rgb(0, 0, 0);
  }

  .body .hide .bg {
    font-weight: 700;
    line-height: 1;
    color: #0e0f10;
    color: var(--main-color);
    opacity: 0;
    font-size: 7em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }

.body .cell:hover .bg,.schedule .body .selected .bg  { /* ทำให้ตัวเลขจางๆ*/
    opacity: 0.6;
    transition: .5s ease-in;
  }
  .body .cellh:hover .bg,.schedule .body .selected .bg  { /* ทำให้ตัวเลขจางๆ*/
    opacity: 0.6;
    transition: .5s ease-in;
  }
.body .hide:hover .bg,.schedule .body .selected .bg  {
    opacity: 0.05;
    transition: .5s ease-in;
  }

 


  
.body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
  }

  /* GRID */

 .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  



  @media (max-width: 960px) { 
    .days {
      display: none;
    }
    .body .col {
      flex-grow: 0;
      flex-basis: calc(100%/1);
      width: calc(100%/1);
    }
    .body .cell .number { /* style ของเลขวันที่ในแต่ละวัน*/
      position: absolute;
      font-size: 82.5%;
      line-height: 1;
      top: .1em;
      right: .2em;
      font-weight: 700;
    }
    .body .cellh .number { /* style ของเลขวันที่ในแต่ละวัน*/
      position: absolute;
      font-size: 82.5%;
      line-height: 1;
      top: .1em;
      right: .2em;
      font-weight: 700;
    }
    .body .cell { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      position: relative;
      height: 3em;
      border-right: 1px solid #eee;
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: #fff;
      background: var(--neutral-color);
      /*transition: 0.5s ease-out;*/
    }
    .body .cellh { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      position: relative;
      height: 3em;
      border-right: 1px solid #eee;
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: rgb(246, 246, 246);
      /*transition: 0.5s ease-out;*/
    }
    .body .hide {
      position: relative;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      border-right: 1px solid #eee;
      border-right: 1px solid var(--border-color);
      overflow: hidden;
      cursor: pointer;
      background: #fff;
      background: var(--neutral-color);
      /*transition: 0.5s ease-out;*/
    }
   
    .body .cell:hover { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      transform: scale(1.02);
      transition: .25s ease-out;
      
    }
    .body .cellh:hover { /* ขนาดช่องแต่ละช่องในปฏิทิน*/
      transform: scale(1.02);
      transition: .25s ease-out;
      
    }
    
  .body .cell {
      border-right: 1px solid #eee;
      border-right: 1px solid var(--border-color);
      border-bottom: 3px solid #eee;
      border-bottom: 3px solid var(--border-color);
      
    }
    .body .disabled {
      display: none;
    }
    .body .selected { /* style ของวันปัจจุบัน*/
      border-left: 6px solid transparent;
      border-image: linear-gradient(45deg, #29a200e0 0%,#000000 40%);
      border-image-slice: 1;
      background: rgb(0, 0, 0);
      color:rgb(255, 255, 255);
      
      
    }
  }
.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.event__calendar{
    display: flex;
    margin-top: 1px;
    margin-bottom: 1px;
    color: rgb(3, 76, 213);
    background: gold;
}

.event__calendar > span {
    font-size: 15px;
    margin-left: 1px;  
}

.event__calendar > p {
    font-size: 10px;
    margin-left: 2px;  
}

.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }


.eventactive__detail {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    display:flex;
    flex-direction: row;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    /*box-shadow: 0 1px 4px rgba(0,0,0,0.4);*/
    height: 65px;
    /*border-radius: 5px;*/
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 5px;
    width: 90%;
    box-shadow: -4px 2px 3px rgba(0, 0, 0, 0.5);
    /*box-shadow: 3px 3px rgba(0,0,0,0.4);*/
    
}

.eventactive__detail:hover {
    transform: scale(1.01);
    transition: .1s ease-out;
  } 
.eventactive__left {
    flex: 0.25 1;
    display:inline-block;
}
.eventactive__right {
   
    flex: 0.75 1;
    display: flex;
    flex-direction: column;
    box-shadow: -3px 0px 3px rgba(0,0,0,0.4);
    
    
}
.eventactive__up {
    display: flex;
    justify-content: space-between;
    margin-right: 0px;
}
.eventactive__name {
    margin-left: 5px;
    margin-top: 2px;
    font-size: 100%;
    font-weight: 700;
}
.eventactive__online {
    font-weight: 700;
    background:red;
    text-align: center;
    border-radius: 20%;
    color:gold;
    padding: 0.5px 7px;
    font-size:13px;
    z-index:10;
    box-shadow: 2px 2px 3px rgb(0, 0, 0);
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 1px;
    margin-right: 2px;
}

.eventactive__before {
    margin-top: 1px;
    
    font-weight: 700;
    text-align: center;
    color:rgb(85, 85, 85);
    padding: 1px 7px;
    font-size:14px;
    display: flex;
}
.eventactive__before > span{
    margin-top: 1px;
    margin-left: 3px;
    font-size: 14px;
    padding: 1px 3px;
    background: gold;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    box-shadow: 1.5px 1.5px rgba(0,0,0,0.4);
}
.eventactive__info {
    margin-left: 5px;
    margin-top: 1px;
    font-size: 85%;
    width: 100%;
    
    
}

.eventactive__location .icon {
    font-size: 110%;
    color: #000000;
    margin-right: 3px;
    margin-bottom: 3px;
    
}

.eventactive__location {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    
}

.eventactive__location > div{
    margin-top: 1px;
    margin-left: 5px;
    font-size: 80%;
}



.eventactive__pic {
    display: block;
    margin: 5px 10px;
    object-fit:cover;
    width: 56px;
    height: 56px;
    overflow:hidden;
    border-radius: 50%;
    box-shadow: -3px -2px 3px rgba(0,0,0,0.4);
    cursor: pointer;
    z-index: 20;
  }

  .eventactive__map {
    margin-top: 3px;
    position: relative;
    margin-right: 5px;
    background:gold;
    text-align: center;
    border-radius: 5px;
    color:black;
    padding: 1px 2px;
    font-size:11px;
    transform: scale(1.05);
    transition: .25s ease-out;
    cursor: pointer;
    box-shadow: 1.5px 1.5px rgba(0,0,0,0.4);
  }
  
  .eventactive__hot__badge{
    position: absolute;
    top: inherit;
    background:rgb(197, 2, 2);
    text-align: center;
    border-radius: 20px;
    color:gold;
    padding: 2px 6px;
    font-size:10px;
    font-weight: 1000;
}
.eventactive__promo__badge{
    position: absolute;
    top: inherit;
    background:rgb(0, 0, 0);
    text-align: center;
    border-radius: 20px;
    color:gold;
    padding: 2px 5px;
    font-size:8px;
    font-weight: 1000;
}


  
.accountsettingdrawer { 
    visibility: hidden;
    opacity: 0;
} 

.accountsettingdrawer.show {
    visibility: visible;
    opacity: 1;
}

.accountsetting__main {
    z-index: 40;
    color: rgb(0, 0, 0);
    margin-top: 2px;
    background:rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    font-size: 85%;
    padding: 4px 6px;
    text-align: left;
    position: absolute;
    width: 180px;
    left:0px;
    top:inherit;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.4);
}
.accountsetting__main > div {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 2px;
    font-weight: 500;

}
.accountsetting__main > div:hover {
    color: rgb(0, 0, 0);
   background: rgb(223, 223, 223);
    cursor: pointer;
    transform: scale(1.05);
    transition: .25s ease-out;
    
    
}


.accountsetting__public > span {
    font-weight: 700;
    margin-bottom: 2px;
}

.accountsetting__private > span {
    font-weight: 700;
    margin-bottom: 2px;
}

@media (max-width: 960px) { 
    .accountsetting__main {
        z-index: 40;
        color: rgb(0, 0, 0);
        margin-top: 2px;
        background:rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        font-size: 85%;
        padding: 4px 6px;
        text-align: left;
        position: absolute;
        width: 130px;
        left:0px;
        top:inherit;
        border-radius: 5px;
        box-shadow: 0 4px 4px rgba(0,0,0,0.4);
    }
}

