.event__all {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    border-radius: 10px;
    background: rgba(237, 237, 237, 0.25);
    
}

/*.event__all:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}*/

.event__public {
    width: fit-content;
    margin-right: 5px;
    height: fit-content;
    background: rgb(30, 92, 208);
    color: #ffffff;
    cursor: pointer;  
    padding: 0 0.5rem;
    border-radius: 7px;
    margin-bottom: 5px;
    
  }
 
  .event__location {
    margin-left: 5px;
    margin-bottom: 3px;
    margin-top: 2px;
    cursor: pointer;
    font-weight: 500;
    width: 225px;
    overflow:hidden;
    font-size:15px;
}
.event__location__badge {
    margin-top: 3px;
    position: absolute;
    right:20px;
    top:inherit;
    background:gold;
    text-align: center;
    border-radius: 20px;
    color:black;
    padding: 1px 4.5px;
    font-size:10px;
    z-index:10;
    cursor: pointer;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}
.event__location__badge:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}

.event__location:hover {
    transform: scale(1.01);
    transition: .25s ease-out;
}

.event__top {
    display: flex;
    justify-content: space-between;
    box-shadow: 3px 3px rgba(0,0,0,0.4);
}


.event__title {
    display: flex;
    z-index: 9;
    flex: 0.85;
}

.event__title .icon {
    
    font-size: 150%;
    color: #000000;
}
.event__type {
    width: 50px;
    margin-top: 3px;
    margin-right: 5px;
    font-size: 80%;
    padding: 1px 4px;
    background: gold;
    color: black;
    cursor: pointer;  
    border-radius: 5px;
    height: fit-content;
    text-align: center;
    box-shadow: 2px 2px rgba(0,0,0,0.4);
    
}

.event__addevent .icon {
    cursor: pointer;
    position: absolute;
    right:47px;
    top:inherit;
    margin-top: 4px;
    margin-left: 3px;
    font-size: 14px;
    padding: 1px 3px;
    background: gold;
    color: black;
    border-radius: 10px;
    text-align: center;
    box-shadow: 1.5px 1.5px rgba(0,0,0,0.4);




}
.event__addevent .icon:hover {
    transform: scale(1.10);
    transition: .25s ease-out;
}

  

.event__with {
    display: flex;
    
    max-width: 100%;
    padding-bottom: 8px;
    border-radius: 15px;
}
.event__pic {
    margin-left: 5px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    width: 100%;

}

.event__info {
    width: 96%;
    padding: 5px 5px;
    margin-top: 3px;
    margin-left: 6px;
    background: white;
    font-family: 'Lato', sans-serif;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    
}
.event__time {
    margin-left: 2px;
    margin-bottom: auto;
    margin-top: auto;
    width: fit-content;
    font-size: 12px;
    font-weight: 700;
}
.event__detail {
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    width: 158px;
    font-size: 14px;
    overflow: hidden;
}
.event__withamount {
    font-weight: 700;
    margin: auto;
    width: fit-content;
}

.event__withamount .icon{
    margin-bottom: 2px;
}


  .event__loading {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #000000; /* Blue */
    border-bottom: 3px solid #fcca00; /* Blue */
    border-radius: 50%;
    margin: auto;
    width: 10px;
    height: 10px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (max-width: 960px) { 
    .event__detail {
        margin-left: 5px;
        width: 145px;
        font-size: 13px;
        overflow: hidden;
        margin-top: auto;
        margin-bottom: auto;
    }
    .event__withamount {
        font-weight: 700;
        margin: auto;
        width: fit-content;
    }
    .event__time {
        margin-left: 2px;
        margin-bottom: auto;
        margin-top: auto;
        width: fit-content;
        font-size: 12px;
        font-weight: 700;
    }
  }